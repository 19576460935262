import React, { useState, Fragment, useEffect } from "react";
import styles from "./Refund.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnRefund, RefundData } from "../../types/TablesTypes/Refund";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import Mocked from "./refund.json";
import RefundsMenu from "../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import Single from "./Single";
import Bulk from "./Bulk";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { setTimeOnDate } from "../../utils";

const Refund = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);

  function closeModalSingle() {
    setIsOpenSingle(false);
  }
  function closeModalBulk() {
    setIsOpenBulk(false);
  }
  const [refund, setRefund] = useState<any>();

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Successful" },
        { name: "Error" },
        { name: "Pending" },
      ],
    },
  ];

  const fetchRefund = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/refund/all?status=${status}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      );
      setRefund(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchRefund();
  }, [bearer]);

  const dataTransaction = () => {
    const tempArr: RefundData[] = [];
    refund?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          amount: `NGN ${numberWithCommas(transaction?.refundAmount)}`,
          customerId: transaction?.customerId,
          transactionReference: transaction?.orderReference,
          date: transaction?.dateCreated,
          status: (
            <StatusView
              status={transaction?.refundStatus}
              green="Approved"
              red="Declined"
              orange="Processing"
            />
          ),
          action: <RefundsMenu customerId={transaction?.customerId} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [refund?.items]);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
          ),
        },
        {
          key: "2",
          label: <p onClick={() => setIsOpenBulk(true)}>Log bulk refunds</p>,
        },
      ]}
    />
  );

  return (
    <Fragment>
      <TableHeader
        pageName="Refunds"
        dataLength={refund?.totalItems}
        data={refund?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        otherbuttonTitle="Log a refund"
        isMenu={true}
        menuComp={menu}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />

      {/* {getTransactionsApi.loading ? (
				<div className={styles.loading} />
			) : ( */}

      <PaginationTable
        data={tableRow ? tableRow : []}
        value={value}
        columns={ColumnRefund ? ColumnRefund : []}
        emptyPlaceHolder="You currently do not have any transactions"
        total={refund?.totalItems}
      />
      {/* )} */}

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Single fn={fetchRefund} closeModal={closeModalSingle} />
      </Modal>
      {/* //MODAL FOR BULK */}
      <Modal
        isOpen={modalIsOpenBulk}
        onRequestClose={closeModalBulk}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Bulk fn={fetchRefund} closeModal={closeModalBulk} />
      </Modal>
    </Fragment>
  );
};

export default Refund;
