import React from "react";

export interface PendingApprovalData {
  date: string;
  narration: string;
  recipient: string;
  amount: string;
  action: any;
}

export const ColumnPendingApproval = [
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Narration",
    accessor: "narration",
  },
  {
    Header: "Recipient",
    accessor: "recipient",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
