export function capitalize(word: string) {
  if (!word) return word; // Check for empty string or null/undefined
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeWords(wordInput: string): string {
  return wordInput
    .split(" ") // Split the sentence into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
}

export function separateCamelCase(input: string): string {
  // Insert a space before all uppercase letters and trim any leading/trailing spaces
  return capitalizeWords(input.replace(/([A-Z])/g, " $1").trim());
}