export const formatCurrencyInput = (currency?: string | number) => {
  if (!currency) {
    return "";
  }
  return currency.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export function formatToCurrency(value: string | number): string | number {
  // Convert the input to a number
  const numericValue =
    typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value;

  // Check if the value is a valid number
  if (isNaN(numericValue)) {
    return value;
  }

  // Use toLocaleString to format the number with two decimal places
  return numericValue.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
