import React from "react";

export interface PaymentLinksData {
  name: string;
  paymentType: string;
  limit: string;
  amount: string;
  isActive: React.ReactNode;
  action: React.ReactNode;
  currency: string;
  authOption: string;
}

export const ColumnPaymentLinks = [
  {
    Header: "Link Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "paymentType",
  },
  {
    Header: "Limit",
    accessor: "limit",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Auth Option",
    accessor: "authOption",
  },
  {
    Header: "Status",
    accessor: "isActive",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export interface PaymentLinksDetailsData {
  customerName: string;
  amount: string;
  phoneNumber: string;
  emailAddress: string;
  paymentFrequency: string;
  noOfPayment: string;
  status: React.ReactNode;
}

export const ColumnPaymentLinksDetails = [
  {
    Header: "Customer Name",
    accessor: "customerName",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumber",
  },
  {
    Header: "Email Address",
    accessor: "emailAddress",
  },
  {
    Header: "Payment Frequency",
    accessor: "paymentFrequency",
  },
  {
    Header: "No of Payment",
    accessor: "noOfPayment",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
