import React, { useState, useEffect, Fragment, useRef } from "react";
import styles from "./Steptwo.module.scss";
import { ReactComponent as UploadIcon } from "../../../../assets/images/Icons/upload-image.svg";
import { ReactComponent as ResetIcon } from "../../../../assets/images/Icons/reset-password.svg";
import OutlineTextInput from "../../../../components/input/OutlineTextInput";
import { useDispatch, useSelector } from "react-redux";
import client from "../../../../api/client";
import {
  openLoader,
  closeLoader,
} from "../../../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
  phoneInputStyle,
  constInputStyle,
  inputStyle,
} from "../../../../constants/toastStyles";
import useApi from "../../../../hooks/useApi";
import currenciesApi from "../../../../api/currencies";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../../utils/formatNumber";
import { Collapse } from "antd";
import { Delete } from "@mui/icons-material";
const { Panel } = Collapse;

const StepTwo = ({ setSteps, stepOneInputs }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  interface initTypes {
    tax: string;
    discount: string;
    invoice_title: string;
    description: string;
    amount?: string;
    customerName: string;
    companyName: string;
    emailAddress: string;
    companyEmailAddress: string;
    dueDate: string;

    // merchantAddress: string;
    // merchantPhone: string;
    customerAddress: string;
  }

  const initProps: initTypes = {
    tax: "",
    discount: "",
    invoice_title: "",
    description: "",
    amount: "",
    customerName: "",
    companyName: "",
    emailAddress: "",
    companyEmailAddress: "",
    dueDate: "",
    // merchantAddress: "",
    // merchantPhone: "",
    customerAddress: "",
  };

  const [inputs, setInputs] = useState(initProps);
  const [tax, setTax] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [discount, setDiscount] = useState("");

  const getCurrencies = useApi(currenciesApi.getCurrencies);
  const currencies = getCurrencies?.data?.data;

  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  let currencyOptions = currencies?.map(function (currency: any) {
    return {
      value: currency.id,
      label: currency.name,
      shortName: currency.shortName,
    };
  });

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      if (inputName === "tax") {
        setTax(newValue); // Sync tax state
      }
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  type itemProps = {
    amount: number;
    quantity: number;
    item: string;
  };

  const [items, setItems] = useState<itemProps[]>([
    { amount: 0, quantity: 0, item: "" },
  ]);

  let handleChange = (index: any, e: any) => {
    let newItems = [...items];
    if (e.target.name.startsWith("amount")) {
      newItems[index].amount = Number(e.target.value);
    } else if (e.target.name.startsWith("quantity")) {
      newItems[index].quantity = Number(e.target.value);
    } else {
      newItems[index].item = e.target.value;
    }
    setItems(newItems);
  };

  let addFormFields = () => {
    setItems([...items, { amount: 0, quantity: 0, item: "" }]);
  };

  let removeFormFields = (i: any) => {
    let newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
  };

  const fetchCurrencies = async () => {
    getCurrencies.request();
  };

  const totalItemsAmount = items
    .map((item) => item.amount * item.quantity)
    .reduce((prev, next) => prev + next);

  const totalAmount =
    !!inputs.discount && !!inputs.tax
      ? totalItemsAmount -
        (totalItemsAmount * parseFloat(inputs.discount)) / 100 +
        (totalItemsAmount * parseFloat(inputs.tax)) / 100
      : !inputs.discount && !!inputs.tax
      ? totalItemsAmount + (totalItemsAmount * parseFloat(inputs.tax)) / 100
      : !!inputs.discount && !inputs.tax
      ? totalItemsAmount -
        (totalItemsAmount * parseFloat(inputs.discount)) / 100
      : totalItemsAmount;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCurrencies();
  }, []);

  const payload = {
    invoiceTitle: inputs.invoice_title,
    description: inputs.description,
    amount: totalAmount,
    dueDate: inputs.dueDate,
    discount: inputs.discount,
    tax: inputs.tax,
    invoiceItems: items,
    currencyId: selectedCurrency?.value,
    businessDetails: {
      customerName: inputs.customerName,
      companyName: inputs.companyName,
      emailAddress: inputs.emailAddress,
      companyEmailAddress: inputs.companyEmailAddress,
      //   merchantAddress: inputs?.merchantAddress,
      //   merchantPhone: inputs?.merchantPhone,
      customerAddress: inputs?.customerAddress,
    },
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/invoice/create", payload)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        history.push("/dashboard/business/invoice");
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        }
      });
  };

  return (
    <div className={styles.business}>
      {/* Form Fields */}
      <div className={styles.businessCard} style={{ marginBottom: "30px" }}>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            {/* Start Of Group of 3 Field */}
            <div className={styles.rowContainer}>
              {/* Invoice Title Field */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="invoice_title"
                inputLabel="Invoice Title"
                inputValue={inputs.invoice_title}
                style={{ height: "40px", marginRight: "15px" }}
              />
              {/* Customer Name Field */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="customerName"
                inputLabel="Customer Name"
                inputValue={inputs.customerName}
                style={{ height: "40px", marginRight: "15px" }}
              />
              {/* Customer Email Field */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="emailAddress"
                inputLabel="Customer Email"
                inputValue={inputs.emailAddress}
                style={{
                  height: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              />
            </div>
            {/* End Of Group of 3 Field */}

            {/* Second Line of 3 Fields */}
            <div className={styles.rowContainer}>
              {/* Company Email number */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="companyEmailAddress"
                inputLabel="Company Email "
                inputValue={inputs.companyEmailAddress}
                style={{
                  height: "40px",
                  marginRight: "15px",
                }}
                notRequired
              />

              {/* Company Name */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="companyName" // Matches "companyName"
                inputLabel="Company Name"
                inputValue={inputs.companyName}
                style={{
                  height: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
                notRequired
              />
              {/* New Date Field */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="dueDate"
                inputLabel="Due Date"
                inputValue={inputs.dueDate ? inputs.dueDate : ""}
                style={{
                  height: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
                inputType="date"
              />
            </div>

            {/*Line of 3 Fields */}
            <div className={styles.rowContainer}>
              {/* Customer Address Field */}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="customerAddress"
                inputLabel="Customer Address"
                inputValue={inputs.customerAddress}
                style={{
                  height: "40px",
                  marginRight: "15px",
                }}
                notRequired
              />
            </div>

            {/* Third Line of 3 Fields */}
            <div className={styles.rowContainer}>
              {/* Tax (%) */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="tax" // Matches "companyName"
                  inputLabel="Add Tax (%)"
                  inputValue={inputs.tax}
                  style={{ height: "40px", marginRight: "15px" }}
                  notRequired
                />
                {Number(inputs.tax) > 100 && (
                  <p
                    style={{
                      fontSize: "small",
                      marginTop: "20px",
                      color: "red",
                    }}
                  >
                    Tax should be less than 100%
                  </p>
                )}
              </div>
              {/* Discount (%) */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="discount" // Matches "companyName"
                  inputLabel="Add discount (%)"
                  inputValue={inputs.discount}
                  style={{ height: "40px", marginRight: "15px" }}
                  notRequired
                />
                {Number(inputs.discount) > 100 && (
                  <p
                    style={{
                      fontSize: "small",
                      marginTop: "20px",
                      color: "red",
                    }}
                  >
                    Discount should be less than 100%
                  </p>
                )}
              </div>
              <div className={styles.currencySelectContainer}>
                <Select
                  defaultValue={selectedCurrency}
                  onChange={setSelectedCurrency}
                  options={currencyOptions}
                  placeholder="Currency"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "55px",
                      minHeight: "55px",
                      borderRadius: "10px",
                      boxShadow: "none",
                      // '&:hover': {
                      // 	border: '1px solid #8E173E', // Optional hover border color
                      // },
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#aaa", // Placeholder color
                      fontSize: "14px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "8px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensures dropdown is above other components
                      borderRadius: "15px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#F8F8F8" : "#FFF",
                      color: "#333",
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
            </div>
            {/* Third Line of 3 Fields Ends */}
          </form>
        </div>
      </div>
      {/* Form Fields Ends */}

      {/* Field to add new items */}
      <div className={styles.businessCard} style={{ marginBottom: "30px" }}>
        <div className={styles.tableContainer}>
          <table className={styles.itemTable}>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Title</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Total</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {items.map((element, index) => (
                <tr key={index}>
                  <td>
                    <textarea
                      placeholder="Enter item name"
                      name={`item${index}`}
                      value={element.item || ""}
                      onChange={(e) => {
                        const words = e.target.value
                          .split(/\s+/)
                          .filter((word) => word.length > 0); // Count valid words
                        if (words.length <= 100) {
                          handleChange(index, e);
                        } else {
                          alert(
                            "Word limit exceeded! Maximum 100 words allowed."
                          );
                        }
                      }}
                      className={styles.inputField}
                      rows={1}
                      onInput={(e) => {
                        (e.target as HTMLTextAreaElement).style.height = "auto"; // Reset height
                        (e.target as HTMLTextAreaElement).style.height = `${
                          (e.target as HTMLTextAreaElement).scrollHeight
                        }px`; // Adjust to content
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Quantity"
                      name={`quantity${index}`}
                      value={element.quantity || ""}
                      onChange={(e) => handleChange(index, e)}
                      className={styles.inputField}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Rate"
                      name={`amount${index}`}
                      value={element.amount || ""}
                      onChange={(e) => handleChange(index, e)}
                      className={styles.inputField}
                    />
                  </td>
                  <td>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>
                        {(element.amount * element.quantity).toFixed(2)}
                      </p>
                      {index > 0 && (
                        <button
                          type="button"
                          className={styles.removeButton}
                          onClick={() => removeFormFields(index)}
                        >
                          <Delete></Delete>
                        </button>
                      )}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className={styles.newItem} onClick={() => addFormFields()}>
            + Add new item
          </p>
        </div>
      </div>
      {/* Field to add new items */}

      {/* Field for description and total items */}
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.businessForm}>
          <div className={styles.notesAndTotal}>
            {/* Left Column - Notes */}
            <div className={styles.notesSection}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="description"
                  inputLabel="Note to recipient"
                  inputValue={inputs.description}
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "transparent",
                  }}
                  multiline={true}
                />
              </form>
            </div>

            {/* Right Column - Total Breakdown */}
            <div className={styles.totalSection}>
              <div className={styles.subTotal}>
                <div className={styles.text}>Subtotal:</div>
                <div className={styles.amount}>
                  {selectedCurrency?.shortName}{" "}
                  {!!totalItemsAmount
                    ? numberWithCommas(totalItemsAmount)
                    : "0.00"}
                </div>
              </div>
              <div className={styles.taxTotal}>
                <div className={styles.text}>Tax (%):</div>
                <div className={styles.amount}>
                  {!!tax ? numberWithCommas(parseInt(tax)) : "0.00"}
                </div>
              </div>
              <hr className={styles.totalDivider} />
              <div className={styles.grandTotal}>
                <div className={styles.text}>Total:</div>
                <div className={styles.amount}>
                  {selectedCurrency?.shortName}{" "}
                  <strong>
                    {!!totalAmount ? numberWithCommas(totalAmount) : "0.00"}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footButtons}>
        <div
          className={styles.backButton}
          onClick={() => {
            setSteps("one");
          }}
        >
          Back
        </div>
        <div
          className={styles.nextButton}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Send invoice
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
