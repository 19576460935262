import React, { useState, Fragment, useEffect } from "react";
import styles from "./QrCodes.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnQrCodes, QrCodesData } from "../../types/TablesTypes/QrCodes";
import TableHeader from "../../components/TableHeader/TableHeader";
import MenuOption from "../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/qrcode2.svg";
import CopyIcon from "../../assets/images/Icons/copy.svg";
import paymentLinksApi from "../../api/paymentLinks";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { computeCustomStyles, customStyles } from "../../constants/modalStyles";
import QrCodeMenu from "../../components/MenuOption/QrCodeMenu/QrCodeMenu";
import CreatePaymentLink from "../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import CreateQrCode from "../../components/ModalContent/CreateQrCode/CreateQrCode";

const QrCodes = () => {
  const dispatch = useDispatch();
  const [paymentLinks, setPaymentLinks] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const getPaymentLinksApi = useApi(paymentLinksApi.getPaymentLinks);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [{ name: "InActive" }, { name: "Active" }],
    },
  ];

  const fetchPaymentLink = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        // `/subsidiary/dashboard/payment/link/subsidiary?status=${status}&fromdate=${fromDate}&todate=${toDate}`
        `/subsidiary/dashboard/payment/qr/fetch`
      );
      setPaymentLinks(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchPaymentLink();
  }, [bearer]);

  const StatusView = ({ status, green, orange, red }: any) => {
    return (
      <div>
        {status === red ? (
          <div className={styles.declined}>Inactive</div>
        ) : status === orange ? (
          <div className={styles.processing}>{orange}</div>
        ) : status === green ? (
          <div className={styles.completed}>Active</div>
        ) : null}
      </div>
    );
  };

  // const dataPaymentLinks = () => {
  //   const tempArr: QrCodesData[] = [];
  //   paymentLinks?.items
  //     ?.slice(0)
  //     .reverse()
  //     .forEach((paymentLink: any, index: number) => {
  //       tempArr.push({
  //         name: paymentLink?.linkName,
  //         QrCodeType: paymentLink?.linkType,
  //         // limit: paymentLink?.limit ?? "No limit",
  //         // currency: paymentLink?.currency,
  //         // amount: !paymentLink?.amount ? "ANY AMOUNT" : `${paymentLink?.currency} ${numberWithCommas(
  //         //   paymentLink?.amount
  //         // )}`,
  //         isActive: (
  //           <>
  //             <StatusView
  //               status={paymentLink?.status}
  //               green={true}
  //               red={false}
  //             />
  //           </>
  //         ),
  //         action: (
  //           <QrCodeMenu
  //             reference={paymentLink?.linkUrl}
  //             status={paymentLink?.status}
  //             dataInfo={paymentLink}
  //             fetchPaymentLink={fetchPaymentLink}
  //           />
  //         ),
  //       });
  //     });
  //   return tempArr;
  // };

  // const dataPaymentLinks = () => {
  //   const tempArr: QrCodesData[] = [];
  //   paymentLinks?.items?.links
  //     ?.slice(0) // Access the `links` array
  //     .reverse() // Reverse the array if needed
  //     .forEach((paymentLink: any) => {
  //       tempArr.push({
  //         name: paymentLink?.linkName,
  //         QrCodeType: paymentLink?.linkType,
  //         isActive: (
  //           <>
  //             <StatusView
  //               status={paymentLink?.status}
  //               green={true}
  //               red={false}
  //             />
  //           </>
  //         ),
  //         action: (
  //           <QrCodeMenu
  //             reference={paymentLink?.linkUrl}
  //             status={paymentLink?.status}
  //             dataInfo={paymentLink}
  //             fetchPaymentLink={fetchPaymentLink}
  //           />
  //         ),
  //       });
  //     });
  //   return tempArr;
  // };

  const dataPaymentLinks = () => {
    const tempArr: QrCodesData[] = [];
    const accountNumber = paymentLinks?.items?.accountNumber;
    const bankName = paymentLinks?.items?.bankName;

    paymentLinks?.items?.links
      ?.slice(0)
      .reverse()
      .forEach((paymentLink: any) => {
        tempArr.push({
          name: paymentLink?.linkName,
          QrCodeType: paymentLink?.linkType,
          isActive: (
            <>
              <StatusView
                status={paymentLink?.status}
                green={true}
                red={false}
              />
            </>
          ),
          action: (
            <QrCodeMenu
              reference={paymentLink?.linkUrl}
              status={paymentLink?.status}
              dataInfo={{
                ...paymentLink, // Pass the entire link data
                accountNumber, // Add account number
                bankName, // Add bank name
              }}
              fetchPaymentLink={fetchPaymentLink}
            />
          ),
        });
      });

    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [paymentLinks?.items]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark overlay
      zIndex: 1000, // Ensure modal appears above all other elements
    },
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%", // Custom width
      maxWidth: "600px", // Limit width for large screens
      minWidth: "300px", // Ensure usability on smaller screens
      height: "auto", // Adjust height dynamically based on content
      minHeight: "60vh", // Ensure it doesn't overflow the viewport
      maxHeight: "90vh", // Ensure it doesn't overflow the viewport
      padding: "20px",
      background: "white", // Dark modal background
      // color: "#ffffff", // White text
      borderRadius: "12px",
      border: "none",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)", // Subtle shadow
      overflow: "auto", // Add scrolling if content overflows
    },
  };

  return (
    <div className={styles.paymentLinkWrapper}>
      <TableHeader
        pageName="QR Codes"
        data={paymentLinks?.items}
        value={value}
        setValue={setValue}
        dataLength={paymentLinks?.items?.links.length}
        // newButton={
        //   <div className={styles.createLink} onClick={() => setIsOpen(true)}>
        //     <LinkIcon className={styles.link} />
        //     Create new QR Code
        //   </div>
        // }
        dropdown={dropdown}
        setDropdown={setDropdown}
        exporting={false}
        filtering={false}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              toDate={toDate}
              fromDate={fromDate}
            />
          </>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateQrCode
          closeModal={closeModal}
          fetchPaymentLink={fetchPaymentLink}
          paymentLinks={paymentLinks?.items || []}
        />
      </Modal>
      {getPaymentLinksApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnQrCodes ? ColumnQrCodes : []}
          emptyPlaceHolder="You currently do not have any payment links"
          total={paymentLinks?.totalItems}
        />
      )}
    </div>
  );
};

export default QrCodes;
