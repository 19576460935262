import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./Exportbutton.module.scss";
import { ReactComponent as DropArrow } from "../../assets/IconsMerchant/export.svg";
import { ReactComponent as DropArrow2 } from "../../assets/images/Icons/drop-arrow.svg";
import * as XLSX from "xlsx";

import { exportToXLS } from "../../utils/exportToExcel";
import { CSVLink, CSVDownload } from "react-csv";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";

const ExportButton = ({
  data,
  fileName,
  newButton,
  fetchDataHandler,
  handleFetchData,
}: any) => {
  var dispatch = useDispatch();

  const [exportData, setExportData] = useState(data);

  const shouldCallApiToGetData =
    handleFetchData && typeof handleFetchData === "function";

  const handleClick = async (type: XLSX.BookType = "xlsx") => {
    if (shouldCallApiToGetData) {
      const data = await handleFetchData();
      exportToXLS(data, fileName, type);
      return;
    }
    exportToXLS(data, fileName, type);
  };

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = async () => {
    if (
      !dropdown &&
      (exportData?.length < 1 || exportData?.length !== data?.length)
    )
      await fetchExportData();
    setDropdown(!dropdown);
  };

  const fetchExportData = async () => {
    if (!fetchDataHandler) return;
    dispatch(openLoader());
    const d = (await fetchDataHandler()) || data || [];
    setExportData([...d]);
    dispatch(closeLoader());
  };
  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        {newButton ? (
          <div className={styles.exportMenu2} onClick={handleDropdown}>
            <div className={styles.filter2}>
              Export{" "}
              <span>
                <DropArrow2 className={styles.dropArrow2} />
              </span>
            </div>

            {dropdown && (
              <div className={styles.exportDropdown}>
                <div
                  onClick={() => handleClick()}
                  style={{ cursor: "pointer" }}
                  className={styles.csv}
                >
                  Export as .xls
                </div>
                {shouldCallApiToGetData ? (
                  <div
                    onClick={() => handleClick("csv")}
                    style={{ cursor: "pointer" }}
                  >
                    Export to .csv
                  </div>
                ) : (
                  <CSVLink
                    data={data ?? []}
                    filename={fileName}
                    className={styles.csv}
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.exportMenu}>
            <div className={styles.export} onClick={handleDropdown}>
              <DropArrow className={styles.dropArrow} />
              &nbsp; Export
            </div>

            {dropdown && (
              <div className={styles.exportDropdown}>
                <div
                  onClick={() => handleClick()}
                  style={{ cursor: "pointer" }}
                >
                  Export as .xls
                </div>
                {shouldCallApiToGetData ? (
                  <div
                    onClick={() => handleClick("csv")}
                    style={{ cursor: "pointer" }}
                    className={styles.csv}
                  >
                    Export to .csv
                  </div>
                ) : (
                  <CSVLink
                    data={data ?? []}
                    filename={fileName}
                    className={styles.csv}
                    id="csvBtn"
                  >
                    Export to .csv
                  </CSVLink>
                )}
              </div>
            )}
          </div>
        )}
      </ClickAwayListener>
    </Fragment>
  );
};

export default ExportButton;
