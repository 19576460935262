import React, { useState, Fragment, useEffect } from "react";
import styles from "./beneficiary.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnBeneficiary,
  BeneficiaryData,
} from "../../types/TablesTypes/beneficiary";
import TableHeader from "../../components/TableHeader/TableHeader";
import client from "../../api/client";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import Bank from "./Bank/Bank";
import Mobile from "./Mobile";
import Merchant from "./Merchant";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import BeneficiaryMenu from "../../components/MenuOption/beneficiaryMenu/BeneficiaryMenu";
import { setTimeOnDate } from "../../utils";

const Beneficiary = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [content, setContent] = useState<any>({});
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenBank, setIsOpenBank] = React.useState(false);
  const [modalIsOpenMobile, setIsOpenMobile] = React.useState(false);
  const [modalIsOpenMerchant, setIsOpenMerchant] = React.useState(false);
  const [beneficiary, setBeneficiary] = useState<any>();

  function closeModalBank() {
    setIsOpenBank(false);
  }
  function closeModalMobile() {
    setIsOpenMobile(false);
  }
  // function closeModalMerchant() {
  // 	setIsOpenMerchant(false);
  // }
  const paymentTypes: any[] = [];
  const [inputs, setInputs] = React.useState<string>("");

  let selectedPaymentTypeOptions = paymentTypes.map(function (
    selectedPaymentType: any
  ) {
    return {
      value: selectedPaymentType.code,
      label: selectedPaymentType.payment_link_name,
    };
  });
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs(event.target.value);
  };

  useEffect(() => {
    if (identifier !== "") {
      setIsOpenMobile(true);
    } else {
      closeModalMobile();
    }
  }, [identifier]);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Type",
      value: status,
      setValue: setStatus,
      selective: [{ name: "Bank" }, { name: "Merchant" }, { name: "Mobile" }],
    },
  ];

  const fetchBeneficiary = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/beneficiary/all?type=${status}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      );
      setBeneficiary(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchBeneficiary();
  }, [bearer]);

  const dataTransaction = () => {
    const tempArr: BeneficiaryData[] = [];
    beneficiary?.data.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          name: transaction.name,
          type: transaction.type,
          recipient: transaction.accountNumber,
          date: moment(transaction?.dateCreated).format("DD MMM YYYY"),
          action: (
            <BeneficiaryMenu
              onOpen={setIsOpenMobile}
              setIdentifier={setIdentifier}
            />
          ),
        });
        setContent(transaction);
      });

    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [beneficiary?.data.items]);

  // Bank, Merchant or Mobile
  // const menu = (
  // 	<Menu
  // 		items={[
  // 			{
  // 				key: '1',
  // 				label: <p onClick={() => setIsOpenBank(true)}>Bank Account</p>,
  // 			},
  // 			{
  // 				key: '2',
  // 				label: <p onClick={() => setIsOpenMobile(true)}>TransactPay Mobile</p>,
  // 			},
  // 			{
  // 				key: '3',
  // 				label: (
  // 					<p onClick={() => setIsOpenMerchant(true)}>TransactPay Merchant</p>
  // 				),
  // 			},
  // 		]}
  // 	/>
  // );

  return (
    <Fragment>
      <TableHeader
        pageName="Beneficiary"
        dataLength={beneficiary?.data.total_items}
        data={beneficiary?.data.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        filtering={false}
        otherbutton={true}
        otherbuttonfunction={setIsOpenBank}
        otherbuttonTitle="Add new beneficiary"
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              toDate={toDate}
              fromDate={fromDate}
            />
          </>
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        value={value}
        columns={ColumnBeneficiary ? ColumnBeneficiary : []}
        emptyPlaceHolder="You currently do not have any beneficiary"
        total={beneficiary?.data?.total_items}
      />

      {/* //MODAL FOR BANK ACCOUNT */}
      <Modal
        isOpen={modalIsOpenBank}
        onRequestClose={closeModalBank}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Bank fn={fetchBeneficiary} closeModal={closeModalBank} />
      </Modal>
      {/* //MODAL FOR TransactPay MOBILE */}
      <Modal
        isOpen={modalIsOpenMobile}
        onRequestClose={closeModalMobile}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <Mobile
          content={content}
          closeModal={closeModalMobile}
          fn={fetchBeneficiary}
        />
      </Modal>

      {/* //MODAL FOR TransactPay MERCHANT */}
      {/* <Modal
				isOpen={modalIsOpenMerchant}
				onRequestClose={closeModalMerchant}
				contentLabel='Example Modal'
				style={customStyles}>
				<Merchant closeModal={closeModalMerchant} />
			</Modal> */}
    </Fragment>
  );
};

export default Beneficiary;
