import React, { useState, Fragment, useEffect } from "react";
import styles from "./PaymentLinkDetail.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnPaymentLinksDetails,
  PaymentLinksDetailsData,
} from "../../../types/TablesTypes/PaymentLinks";
import TableHeader from "../../../components/TableHeader/TableHeader";
import MenuOption from "../../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import CopyIcon from "../../assets/images/Icons/copy.svg";
import paymentLinksApi from "../../../api/paymentLinks";
import useApi from "../../../hooks/useApi";
import { numberWithCommas } from "../../../utils/formatNumber";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import {
  computeCustomStyles,
  customStyles,
} from "../../../constants/modalStyles";
import PaymentLinkMenu from "../../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import CreatePaymentLink from "../../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import { ToastErrorStyles } from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import client from "../../../api/client";
import FilteredUsage from "../../../components/filterUsableComponent/FilteredUsage";
import { ButtonTab } from "../../../components/Tabs/Tabs";
import { capitalize, setTimeOnDate } from "../../../utils";
import { useParams } from "react-router-dom";
import StatusView from "../../../components/StatusView/StatusView";
import { Pagination } from "../../../components/Pagination/Pagination";

interface Props {
  id?: string; // Ensure `id` can be optional
}

const PaymentLinkDetail = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const [paymentLinks, setPaymentLinks] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const getPaymentLinksApi = useApi(paymentLinksApi.getPaymentLinks);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  //start
  const [paymentDetails, setPaymentDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  function closeModal() {
    setIsOpen(false);
  }
  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [frequency, setFrequency] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const [searchableColumns, setSearchableColumns] = useState("");
  const [keyword, setKeyword] = useState("");
  // const [pageNumber, setPageNumber] = useState<number>(1);
  // const [pageSize, setPageSize] = useState<number>(10);
  // const [filter, setFilter] = useState<number | null>(null);

  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setCustomerName("");
    setEmail("");
    setFrequency("");
    setDropdown(false);
    setBearer(false);
    setSearchableColumns("");
    setKeyword("");
    // setPageNumber(1);
    // setPageSize(10);
    // setFilter(null);
  };

  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [{ name: "New" }, { name: "Active" }],
    },
    {
      id: 2,
      name: "Email",
      value: email,
      setValue: (val: React.SetStateAction<string>) => {
        setEmail(val);
        setKeyword(val); // Store value in keyword
        setSearchableColumns("Email"); // Set the search column
      },
    },
    {
      id: 3,
      name: "Name",
      value: customerName,
      setValue: (val: React.SetStateAction<string>) => {
        setCustomerName(val);
        setKeyword(val); // Store value in keyword
        setSearchableColumns("CustomerName"); // Set the search column
      },
    },
    {
      id: 4,
      name: "Frequency",
      value: frequency,
      setValue: (val: React.SetStateAction<string>) => {
        setFrequency(val);
        setKeyword(val); // Store value in keyword
        setSearchableColumns("Frequency"); // Set the search column
      },
    },
    // {
    //   id: 4,
    //   name: "Filter",
    //   value: filter,
    //   setValue: setFilter,
    // },
    // {
    //   id: 5,
    //   name: "Page Number",
    //   value: pageNumber,
    //   setValue: setPageNumber,
    // },
    // {
    //   id: 6,
    //   name: "Page Size",
    //   value: pageSize,
    //   setValue: setPageSize,
    // },
  ];

  //pagination
  // const INITIAL_PAGE_CONFIG = {
  //   totalPages: 10,
  //   currentPage: 1,
  //   pageSize: 20,
  // };

  // const [pageConfig, setPageConfig] = useState(INITIAL_PAGE_CONFIG);

  // const handlePageUpdate = (page: number) => {
  //   setPageConfig((pageConfig) => ({ ...pageConfig, currentPage: page }));
  // };

  const fetchDetails = async () => {
    try {
      const params: any = {
        StartDate: fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : "",
        EndDate: toDate ? setTimeOnDate(toDate, 23, 59, 59) : "",
        SelectedSearchableColumn: searchableColumns || undefined,
        Keyword: keyword || undefined,
        // PageNumber: pageNumber,
        // PageSize: pageSize,
        // Filter: filter || undefined,
      };

      setLoading(true);
      const response = await client.get(
        `/subsidiary/dashboard/recurringLink/${id}/details`,
        { params }
      );
      setPaymentDetails(response.data);
    } catch (error) {
      setError("Error fetching payment link details");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [bearer, id]);

  const dataPaymentLinks = () => {
    const tempArr: PaymentLinksDetailsData[] = [];
    paymentDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((paymentDetail: any, index: number) => {
        tempArr.push({
          customerName: paymentDetail?.customerName,
          amount: !paymentDetail?.amount
            ? "ANY AMOUNT"
            : `${paymentDetail?.currency} ${numberWithCommas(
                paymentDetail?.amount
              )}`,
          phoneNumber: paymentDetail?.paymentType ?? "Not Provided",
          emailAddress: paymentDetail?.emailAddress ?? "Not Provided",
          paymentFrequency: paymentDetail?.frequency ?? "Not Provided",
          noOfPayment: paymentDetail?.numberOfPayments ?? "Not Provided",
          status: (
            <>
              <StatusView
                status={paymentDetail?.status}
                green={"New"}
                red={"Old"}
                // orange={"Processing || Pending"}
                // blue={"Awaiting Verification"}
                // indigo="Awaiting Approval"
              />
            </>
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [paymentDetails?.items]);

  return (
    <div className={styles.paymentLinkWrapper}>
      <TableHeader
        pageName="Payment Subscribers"
        data={paymentDetails?.items}
        value={value}
        setValue={setValue}
        dataLength={paymentDetails?.totalCount}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      {getPaymentLinksApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          <PaginationTable
            value={value}
            data={tableRow ? tableRow : []}
            columns={ColumnPaymentLinksDetails ? ColumnPaymentLinksDetails : []}
            emptyPlaceHolder="You currently do not have any payment links"
            total={paymentDetails?.data?.pageSize}
          />
          {/* <Pagination
            totalPages={paymentDetails?.data?.pageSize}
            currentPage={pageConfig.currentPage}
            handlePageChange={handlePageUpdate}
          /> */}
        </>
      )}
    </div>
  );
};

export default PaymentLinkDetail;
