import React, { useState, Fragment, useEffect } from "react";
import styles from "./Customers.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnCustomers,
  CustomersData,
} from "../../types/TablesTypes/Customers";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/focusadd.svg";
import customersApi from "../../api/customers";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { setTimeOnDate } from "../../utils";

const Customers = () => {
  const [value, setValue] = useState("");
  const [customers, setCustomers] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getCustomersApi = useApi(customersApi.getCustomers);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const history = useHistory();

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [customername, setCustomername] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setCustomername("");
    setMobilenumber("");
    setEmail("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [{ name: "Active" }, { name: "Blacklisted" }],
    },
    {
      id: 2,
      name: "Email Address",
      value: email,
      setValue: setEmail,
    },
    {
      id: 3,
      name: "Customer Name",
      value: customername,
      setValue: setCustomername,
    },
    {
      id: 3,
      name: "Mobile Number",
      value: mobilenumber,
      setValue: setMobilenumber,
    },
  ];

  const fetchCustomers = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/fetch/customers?status=${status}&email=${email}&customername=${customername}&mobilenumber=${mobilenumber}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}s`
      );
      setCustomers(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [bearer]);

  const menuOptions = [
    {
      id: 1,
      name: (status: string) => {
        return status === "Active"
          ? "Blacklist Customer"
          : "Whitelist Customer";
      },
      icon: DeactivateIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string
      ) => {
        const payload = {
          customerEmail: customerEmail,
          action: status === "Active" ? "blacklist" : "whitelist",
          reason: "test",
        };
        e.preventDefault();
        client
          .post("/subsidiary/customer/blacklist-whitelist", payload)
          .then((data: any) => {
            fetchCustomers();
            const message = data?.data?.message;
            dispatch(
              openToastAndSetContent({
                toastStyles: ToastSuccessStyles,
                toastContent: message,
              })
            );
          })
          .catch((error: any) => {
            if (error.response) {
              const { message } = error?.response?.data;
              dispatch(
                openToastAndSetContent({
                  toastStyles: ToastErrorStyles,
                  toastContent: message,
                })
              );
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      },
    },
    {
      id: 2,
      name: (id: number) => {
        return id ? "View details" : null;
      },
      icon: ViewIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string,
        customerId: number
      ) => {
        history.push(
          `/dashboard/business/customers/customer-details/${customerId}`
        );
      },
    },
  ];

  const dataCustomers = () => {
    const tempArr: CustomersData[] = [];
    customers?.items
      ?.slice(0)
      .reverse()
      .forEach((customer: any, index: number) => {
        return tempArr.push({
          customerName: customer?.customerName,
          emailAddress: customer?.emailAddress,
          mobileNumber: customer?.mobileNumber,
          countryName: customer?.countryName,
          status: (
            <StatusView
              status={customer?.status}
              green="Active"
              red="Blacklisted"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          customer?.emailAddress,
                          e,
                          customer?.status,
                          customer?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(customer?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(customer?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataCustomers());
  }, [customers?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Customers"
        data={customers?.items}
        dataLength={customers?.items.length}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Add New Customer
          </div>
        }
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer
          fetchCustomers={fetchCustomers}
          closeModal={closeModal}
        />
      </Modal>
      {getCustomersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnCustomers ? ColumnCustomers : []}
          emptyPlaceHolder="You currently do not have any customers"
          total={customers?.items.length}
        />
      )}
    </Fragment>
  );
};

export default Customers;
