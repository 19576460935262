import React, { useState, Fragment, useEffect } from "react";
import styles from "./Balancehistory.module.scss";
import { BalanceHistoryData } from "../../types/TablesTypes/balanceHistory";
import TableHeader from "../../components/TableHeader/TableHeader";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreatePaymentLink from "../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import moment from "moment";
import { useParams } from "react-router-dom";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import { ToastErrorStyles } from "../../constants/toastStyles";
import BalanceMenu from "../../components/MenuOption/BalanceMenu/BalanceMenu";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import StatusView from "../../components/StatusView/StatusView";
import Table from "../../components/table";
import { Pagination } from "../../components/Pagination/Pagination";
import { setTimeOnDate } from "../../utils";

const BalanceHistory = () => {
  const { id } = useParams<{ id: any }>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.appSettingsReducer);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [reference, setReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setReference("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Direction",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Debit", id: "D" },
        { name: "Credit", id: "C" },
      ],
    },
    {
      id: 2,
      name: "Reference",
      value: reference,
      setValue: setReference,
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  };

  const fetchBalanceHistory = () => {
    dispatch(openLoader());
    client
      .get(
        `/subsidiary/dashboard/fetch/wallet/history?&currency=${currency}&page=${page}&limit=${pageSize}&direction=${status}&reference=${reference}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      )
      .then((res: any) => {
        setCustomerDetails(res);
        dispatch(closeLoader());
        setBearer(false);
      })
      .catch((error) => {
        dispatch(closeLoader());
        setBearer(false);

        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    fetchBalanceHistory();
  }, [bearer, currency, page, pageSize]);

  const columns = [
    {
      Header: "Due Date",
      accessor: "dateCreated",
      Cell: ({ value }: any) => {
        return <>{moment(value).format("MMMM Do YYYY, h:mm a")}</>;
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }: any) => {
        const { amount } = row.original;
        return (
          <>
            {currency} {numberWithCommas(amount)}
          </>
        );
      },
    },
    {
      Header: "Direction",
      accessor: "direction",
      Cell: ({ row }: any) => {
        const { direction } = row.original;
        return <StatusView status={direction} green={"Credit"} red={"Debit"} />;
      },
    },
    {
      Header: "Balance",
      accessor: "balance",
      Cell: ({ row }: any) => {
        const { balance } = row.original;
        return (
          <>
            {currency} {numberWithCommas(balance)}
          </>
        );
      },
    },
    {
      Header: "Details",
      accessor: "remarks",
    },
    {
      Header: "Action",
      Cell: ({ row }: any) => {
        const balance = row.original;
        return <BalanceMenu data={balance} />;
      },
    },
  ];

  const dataPaymentLinks = () => {
    const tempArr: BalanceHistoryData[] = [];
    customerDetails?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((balance: any, index: number) => {
        tempArr.push({
          date: moment(balance?.dateCreated).format("MMMM Do YYYY, h:mm a"),
          amount: `${currency} ${numberWithCommas(balance?.amount)}`,
          direction: (
            <>
              <StatusView
                status={balance?.direction}
                green={"Credit"}
                red={"Debit"}
              />
            </>
          ),
          balance: `${currency} ${numberWithCommas(balance?.balance)}`,
          details: balance?.remarks,
          action: <BalanceMenu data={balance} />,
        });
      });
    return tempArr;
  };

  const handleExport = async () => {
    dispatch(openLoader());
    try {
      let items: any[] = [];

      const totalPages = customerDetails?.data?.totalPages;
      if (!totalPages) {
        return items;
      }
      const response: any = await client.get(
        `/subsidiary/dashboard/fetch/wallet/history?currency=${currency}&direction=${status}&reference=${reference}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${
          toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""
        }&download=true`
      );
      return response?.data?.items ?? [];
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(closeLoader());
    }
  };

  return (
    <Fragment>
      <TableHeader
        pageName="Balance History"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.totalItems}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        fetchExportDataHandler={dataPaymentLinks}
        handleFetchData={handleExport}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreatePaymentLink
          closeModal={closeModal}
          fetchPaymentLink={fetchBalanceHistory}
        />
      </Modal>
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : customerDetails?.data ? (
        // <PaginationTable
        //   data={tableRow ? tableRow : []}
        //   columns={ColumnBalanceHistory ? ColumnBalanceHistory : []}
        //   emptyPlaceHolder="You currently do not have any balance history"
        //   total={customerDetails?.data?.totalPages}
        // />
        <>
          <Table columns={columns} data={customerDetails?.data?.items} />
          <div style={{ marginBlock: 5 }}>
            <Pagination
              currentPage={page}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              totalPages={customerDetails?.data?.totalPages}
              pageSize={pageSize}
            />
          </div>
        </>
      ) : (
        <>Loading...</>
      )}
    </Fragment>
  );
};

export default BalanceHistory;
