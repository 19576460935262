import React, { useState, ReactNode, Fragment, useEffect, useRef } from "react";
import styles from "./Payment.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import LinkIcon from "@mui/icons-material/Link";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import qrCode from "../../../assets/images/Icons/qr_code.svg";
import editPen from "../../../assets/images/Icons/editPen.svg";
import mastercard from "../../../assets/images/Logos/mastercard.svg";
import visa from "../../../assets/images/Logos/visa.svg";
import transactpay from "../../../assets/images/Logos/transactpay_final_logo_icon1.png";
import verve from "../../../assets/images/Logos/verve.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import { getEnvironmentConfig } from "../../../utils/getEnvironmentConfig";
import {
  Download,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";
import { useHistory } from "react-router-dom";

type MenuProps = {
  menuContent: string;
};
const PaymentLinkMenu = ({
  reference,
  status,
  fetchPaymentLink,
  dataInfo,
}: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  const [modalIsOpenGenerateQr, setIsOpenGenerateQr] = React.useState(false);
  const [copied, setCopied] = useState(false);

  const history = useHistory(); // Initialize the navigation function

  if (copied) {
    dispatch(
      openToastAndSetContent({
        toastContent: "Payment link copied",
        toastStyles: ToastSuccessStyles,
      })
    );
  }
  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const closeModalGenerateQr = () => {
    setIsOpenGenerateQr(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const body = {
    action: status === true ? "disable" : "activate",
    reference,
  };

  const updatePaymentLink = async () => {
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/payment/link/update-status",
        body
      );

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const getLinkByReference = () => {
    if (dataInfo?.paymentType === "Subscription Payments") {
      // history.push("/dashboard/business/payment-links-details");
      history.push(`/dashboard/business/${dataInfo.id}/payment-links-details`);

      // GET / subsidiary / dashboard / recurringLink / { id } / details;
    } else {
      setIsOpenDetails(true);
    }
    setReferenceData(dataInfo);
  };

  const copyPaymentLink = () => {};
  const handleStatus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    closeModal();
    // getLinkByReference();
    updatePaymentLink();
  };
  const qrRef = useRef<HTMLDivElement | null>(null);

  const downloadQRCode = () => {
    if (qrRef.current) {
      html2canvas(qrRef.current).then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "QrCode.png";
        link.click();
      });
    }
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={LinkOffIcon}
                optionName={
                  status === true ? "Deactivate Link" : "Activate Link"
                }
                onclick={() => setIsOpen(true)}
              />
              <Menus
                icon={ViewIcon}
                optionName="View Details"
                onclick={() => {
                  getLinkByReference();
                }}
              />

              <Menus
                icon={CopyIcon}
                optionName="Copy Link"
                onclick={() => {
                  navigator.clipboard.writeText(dataInfo?.paymentLinkUrl);
                  setCopied(true);
                }}
              />
              <Menus
                icon={ViewIcon}
                optionName="Initiate Payment"
                onclick={() =>
                  window.open(dataInfo?.paymentLinkUrl, "_blank")?.focus()
                }
              />
              <Menus
                icon={qrCode}
                optionName="Generate Qr Code"
                onclick={() => {
                  setIsOpenGenerateQr(!modalIsOpenGenerateQr);
                }}
              />
              {/* <Menus
								icon={editPen}
								optionName='Edit Payment Link'
								onclick={() => {
									setIsOpenGenerateQr(!modalIsOpenGenerateQr);
								}}
							/> */}
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        {status === true ? (
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <div className={styles.background}>
                <div className={styles.linkDetails}>Deactivate link</div>
                <CloseIcon onClick={closeModal} />
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.confirmation}>
                Are you sure you want to deactivate this link?
              </div>
              <div className={styles.link}>
                Deactivating this link will make it no longer accessible.
              </div>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.cancel} onClick={closeModal}>
                No, cancel
              </div>
              <div
                className={styles.deactivate}
                onClick={(e) => {
                  handleStatus(e);
                }}
              >
                Yes, deactivate link
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <div className={styles.background}>
                <div className={styles.linkDetails}>Activate link</div>
                <CloseIcon onClick={closeModal} />
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.confirmation}>
                Are you sure you want to Activate this link?
              </div>
              <div className={styles.link}>
                Activating this link will make it accessible.
              </div>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.cancel} onClick={closeModal}>
                No, cancel
              </div>
              <div
                className={styles.deactivate}
                onClick={(e) => {
                  handleStatus(e);
                }}
              >
                Yes, activate link
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        <PaymentModal
          closeModal={closeModalDetails}
          reference={referenceData}
        />
      </Modal>

      {/* modal for qr code */}
      <Modal
        isOpen={modalIsOpenGenerateQr}
        onRequestClose={closeModalGenerateQr}
        style={customStyles}
        contentLabel="Generate Qr Code"
      >
        <div className={styles.modalBody}>
          <div
            style={{
              backgroundColor: "#8E173E",
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "4px 10px",
            }}
          >
            {/* <button style={{ padding:'3px', margin:'0px 3px', borderRadius:'100%',border:'1px solid white' , backgroundColor: '#8E173E'}} onClick={downloadQRCode}><Download  style={{ color: 'white' }} /></button> */}
            <CloseIcon onClick={closeModalGenerateQr} />
          </div>
          <div
            style={{
              fontSize: "medium",
              fontWeight: "500",
              padding: "5px 30px",
              textAlign: "center",
            }}
          >
            {" "}
            Download and share your payment link QR code with customers to
            easily complete their payments{" "}
          </div>
          <div className={styles.modalContent} ref={qrRef}>
            <div
              style={{
                fontSize: "large",
                marginBottom: "10px",
                fontWeight: "700",
              }}
            >
              {dataInfo?.name}
            </div>
            <div className={styles.qrcontainer}>
              <p
                style={{
                  fontSize: "large",
                  fontWeight: "500",
                  margin: "15px 0px",
                }}
              >
                Scan here to pay <KeyboardDoubleArrowDown />
              </p>
              <div className={styles.qrbox}>
                {" "}
                <QRCodeCanvas
                  value={dataInfo?.paymentLinkUrl}
                  size={120}
                  title="Transactpay Payment Link"
                />
              </div>
              <p
                style={{
                  fontSize: "large",
                  fontWeight: "500",
                  marginTop: "5px",
                }}
              >
                We accept:
              </p>
              <div className={styles.iconBox}>
                {" "}
                <img
                  src={mastercard}
                  className={styles.qrIcon}
                  alt="Mastercard"
                />{" "}
                <img src={verve} className={styles.qrIcon} alt="Mastercard" />{" "}
                <img src={visa} className={styles.qrIcon} alt="Mastercard" />{" "}
              </div>
            </div>
            <div
              style={{ fontSize: "14", marginTop: "10px", color: "#8E173E" }}
            >
              Powered by{" "}
              <img
                src={transactpay}
                style={{
                  width: "18px",
                  height: "18px",
                  marginLeft: "6px",
                  objectFit: "contain",
                }}
              />{" "}
              Transactpay
            </div>
          </div>
          <div
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              color: "white",
              alignItems: "center",
              padding: "10px 10px",
            }}
          >
            <button
              onClick={() => {
                navigator.clipboard.writeText(dataInfo?.paymentLinkUrl);
                setCopied(true);
              }}
              style={{
                padding: "6px 15px",
                margin: "0px 3px",
                borderRadius: "10px",
                border: "none",
                backgroundColor: "#8E173E",
              }}
            >
              Copy Link
            </button>
            <button
              style={{
                padding: "6px 15px",
                margin: "0px 3px",
                borderRadius: "10px",
                border: "none",
                backgroundColor: "#8E173E",
              }}
              onClick={downloadQRCode}
            >
              Download
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default PaymentLinkMenu;
