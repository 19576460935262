import React, { useMemo, useEffect, useState } from "react";
import styles from "./Customers.module.scss";
import FundIcon from "../../../assets/images/funded.svg";
import { useTable, useSortBy, useFilters, Column } from "react-table";
import { NavLink, Link } from "react-router-dom";

type DataColumn = {
  data: any;
  columns: any;
};

const Table = ({ data, columns }: DataColumn) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFilters, useSortBy);
  return (
    <table
      {...getTableProps()}
      style={{ width: "100%" }}
      className={styles.table}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.tableHead}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableBody}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "20px",
                      fontFamily: "Steradian",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#293333",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
