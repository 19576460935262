import React, { useEffect } from "react";
import styles from "./Initiatepayout.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import Select from "react-select";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import BeneficiaryPayout from "./BeneficiaryPayout";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import PayoutVerificationModal from "../PayoutVerificationModal/PayoutVerificationModal";
import { Delete, Download } from "@mui/icons-material";
import { IoInformationCircle } from "react-icons/io5";
import FileUpload from "../../input/FileUpload";

const InitiatePayout = ({ closeModal, fn, beneficiary, fn2 }: any) => {
  const dispatch = useDispatch();
  const [banks, setBanks] = React.useState<any>([]);
  const [selectedBank, setSelectedBank] = React.useState<any>();
  const [selectedType, setSelectedType] = React.useState<any>({
    value: "Bank",
    label: "Bank",
  });
  const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);
  const { currencies, currency } = useSelector(
    (state) => state?.appSettingsReducer
  );
  const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>(
    {
      label: currency,
      value: currency,
    }
  );
  const [resolveData, setResolvedData] = React.useState<any>();
  const [active, setActive] = React.useState("new");
  const [fileUploaded, setFileUploaded] = React.useState<File | null>(null);

  const [modalIsOpen, setIsModalOpen] = React.useState({
    isOpened: false,
    data: null,
  });

  const Type = [
    {
      id: 1,
      name: "Bank",
    },
    {
      id: 2,
      name: "Merchant",
    },
    // {
    // 	id: 3,
    // 	name: 'Mobile',
    // },
  ];

  let selectedTypeOptions = Type?.map(function (ty: any) {
    return {
      value: ty.name,
      label: ty.name,
    };
  });
  const closePayoutVerificationModal = () => {
    setIsModalOpen({ isOpened: false, data: null });
    fn();
    fn2();
    closeModal();
  };

  const fetchBanks = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/banks");
      setBanks(data?.data?.banks);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handlePayoutResponse = async (cbData: any) => {
    try {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: cbData?.message ?? "",
        })
      );
      if (cbData.statusCode == "00") {
        fn();
        fn2();
        closeModal();
        return;
      } else if (cbData.statusCode == "007") {
        setIsModalOpen({ isOpened: true, data: cbData });
      } else {
        const { message } = cbData?.message || {
          message: "Unable to initiate payout at the moment",
        };
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {
        message: "Unable to get auth code, please try again",
      };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  let selectedBanksOptions = banks?.map(function (bank: any) {
    return {
      value: bank.id,
      label: bank.name,
      code: bank.bankCode,
    };
  });

  interface initTypes {
    recipientCity: any;
    recipientAddress: any;
    recipientEmail: any;
    recipientStreetNumber: string;
    recipientStreetName: string;
    recipientPostalCode: any;
    bankRoutingNumber: any;
    swiftCode: any;
    recipientName: string;
    accountNumber: string | undefined;
    description: string;
    amount: number | undefined;
    recipientPhoneNumber: any;
    bankAddress: string | undefined;
    bankName: string | undefined;
  }

  const initProps: initTypes = {
    accountNumber: undefined,
    description: "",
    amount: undefined,
    recipientName: "",
    recipientEmail: "",
    recipientAddress: "",
    recipientStreetNumber: "",
    recipientStreetName: "",
    recipientPostalCode: undefined,
    bankRoutingNumber: undefined,
    swiftCode: undefined,
    recipientCity: undefined,
    recipientPhoneNumber: undefined,
    bankAddress: "",
    bankName: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      inputs.accountNumber &&
      inputs.amount &&
      // !!resolveData?.data?.accountName &&
      inputs.description
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  useEffect(() => {
    fetchBanks();
    // fetchCurrencies();
  }, []);

  useEffect(() => {
    const bene = currencies?.map((x: any) => ({
      value: x.shortName,
      label: `${x.shortName}`,
    }));
    // console.log({ bene })
    if (bene && bene.length > 0) setCurrenciesOption([...bene]);
  }, [currencies]);

  const payloadBank = {
    // bankCode:
    //   selectedBank?.code ?? selectedType?.value
    //     ? selectedCurrencyInput.value !== "NGN"
    //     : selectedBank?.value,
    bankCode:
      selectedCurrencyInput.value === "USD"
        ? "USD"
        : selectedBank?.code ??
          (selectedType?.value
            ? selectedCurrencyInput.value !== "NGN"
            : selectedBank?.value),
    // bankId: selectedBank?.value,
    ...(selectedCurrencyInput.value !== "USD" && {
      bankId: selectedBank?.value,
    }),
    creditCurrency: selectedCurrencyInput.value,
    type: selectedType?.value,
    AccountName: resolveData?.data?.accountName ?? inputs?.recipientName,
    amount: inputs.amount,
    description: inputs.description,
    accountNumber: inputs.accountNumber,
    recipientName: inputs?.recipientName,
    bankAddress: inputs?.bankAddress,
    recipientAddress: inputs.recipientAddress,
    bankRoutingNumber: inputs.bankRoutingNumber,
    swiftCode: inputs.swiftCode,
    debitCurrency: selectedCurrencyInput.value,
    // recipient: inputs.recipientEmail || inputs?.recipientName,
    // recipientPhoneNumber: inputs.recipientPhoneNumber,
    // category
    // payoutReference
  };

  const initiatePayout = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post("/subsidiary/payout/initiate", {
        payoutDetails: [payloadBank],
      });
      // console.log(payloadBank)
      const message = data?.data?.message;
      handlePayoutResponse(data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const resolveAccount = async () => {
    if (
      !!inputs?.accountNumber &&
      selectedCurrencyInput.value == "NGN" &&
      selectedType?.value?.toLowerCase() == "bank" &&
      inputs?.accountNumber?.length < 10
    )
      return;
    dispatch(openLoader());
    try {
      const data: any = await client.post("/subsidiary/beneficiary/resolve", {
        type: selectedType?.value,
        accountNumber: inputs?.accountNumber,
        bankCode: selectedBank?.code,
      });
      setResolvedData(data?.data);
      // console.log('just to:', data.data);
      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };
  useEffect(() => {
    if (
      inputs?.accountNumber &&
      selectedBank?.code !== "" &&
      selectedCurrencyInput.value === "NGN"
    )
      resolveAccount();
  }, [inputs?.accountNumber, selectedBank?.code]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    initiatePayout();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Transfer</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.buttonSwitch}>
        <button
          onClick={() => setActive("new")}
          id={active === "new" ? styles.buttonSwitchButton : ""}
          className={styles.buttonSwitchButton}
        >
          New Transfer
        </button>
        <button
          onClick={() => setActive("ben")}
          id={active === "ben" ? styles.buttonSwitchButton : ""}
          className={styles.buttonSwitchButton}
        >
          Beneficiary
        </button>
        {/* <button
          onClick={() => setActive("bulk")}
          id={active === "bulk" ? styles.buttonSwitchButton : ""}
          className={styles.buttonSwitchButton}
        >
          Bulk Upload
        </button> */}
      </div>
      {active === "new" && (
        <>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="amount"
                inputLabel="Amount"
                inputValue={inputs.amount}
              />
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Select Currency</label>
                <Select
                  defaultValue={selectedCurrencyInput}
                  onChange={setSelectedCurrencyInput}
                  options={currenciesOption}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                    }),
                  }}
                />
              </div>
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Transfer Type</label>
                <Select
                  defaultValue={selectedType}
                  onChange={setSelectedType}
                  options={selectedTypeOptions}
                  // styles={customStyles}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                    }),
                  }}
                />
              </div>

              {selectedType?.value === "Bank" && (
                <>
                  {selectedCurrencyInput.value === "NGN" ? (
                    <>
                      <div className={styles.modalselect}>
                        <label className={styles.inputLabel}>Bank</label>
                        <Select
                          defaultValue={selectedBank}
                          onChange={setSelectedBank}
                          options={selectedBanksOptions}
                          className={styles.select}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                              width: "100%",
                            }),
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <OutlineTextInput
                      handleChange={updateProps}
                      inputName="bankName"
                      inputLabel="Bank Name"
                      inputValue={inputs.bankName}
                    />
                  )}
                </>
              )}
              {selectedType?.value === "Mobile" && (
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Mobile Number"
                  inputValue={inputs.accountNumber}
                />
              )}
              {selectedType?.value === "Merchant" && (
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Merchant ID"
                  inputValue={inputs.accountNumber}
                />
              )}
              {selectedType?.value === "Bank" && (
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Account number"
                  inputValue={inputs.accountNumber}
                />
              )}

              {resolveData && (
                <p>
                  Account Name:{" "}
                  <span style={{ color: "green" }}>
                    {resolveData?.data?.accountName}
                  </span>{" "}
                </p>
              )}
              {selectedCurrencyInput.value !== "NGN" && (
                <>
                  <OutlineTextInput
                    handleChange={updateProps}
                    inputName="recipientName"
                    inputLabel="Recipient Name"
                    inputValue={inputs.recipientName}
                  />
                  <OutlineTextInput
                    handleChange={updateProps}
                    inputName="recipientAddress"
                    inputLabel="Recipient Address"
                    inputValue={inputs.recipientAddress}
                  />
                  <OutlineTextInput
                    handleChange={updateProps}
                    inputName="bankAddress"
                    inputLabel="Bank Address"
                    inputValue={inputs.bankAddress}
                  />
                  <OutlineTextInput
                    handleChange={updateProps}
                    inputName="bankRoutingNumber"
                    inputLabel="Bank Routing Number/Sort Code"
                    inputValue={inputs.bankRoutingNumber}
                  />
                  <OutlineTextInput
                    handleChange={updateProps}
                    inputName="swiftCode"
                    inputLabel="Swift Code"
                    inputValue={inputs.swiftCode}
                  />
                </>
              )}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="description"
                inputLabel="Description"
                inputValue={inputs.description}
                notRequired={false}
                // multiline={true}
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Initiate payout
              </div>
            ) : (
              <div className={styles.notClickable}> Initiate payout</div>
            )}
          </div>
        </>
      )}
      {active === "ben" && (
        <BeneficiaryPayout
          payoutCb={handlePayoutResponse}
          fn={fn}
          closeModal={closeModal}
          beneficiary={beneficiary}
        />
      )}
      {active === "bulk" && (
        <>
          <div
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            className={styles.businessForm}
          >
            <strong>
              <p>
                You are about to log bulk payouts <br /> Please note:-{" "}
              </p>
            </strong>
            <ul style={{ listStyleType: "square", paddingLeft: "20px" }}>
              <li>The file format is either CSV or XLSX</li>
              <li>
                The file should contain the following columns
                <ul style={{ listStyleType: "disc" }}>
                  <li>Account Number</li>
                  <li>Bank</li>
                  <li>Amount</li>
                  <li>Description</li>
                </ul>
              </li>
              <li>
                The order of the columns should be the same as the order in
                which they are listed above with the first rows as headers
              </li>
            </ul>
            <span>
              {" "}
              <a
                href="/../../../../src/assets/documentSamples/ngn_amount_and_debit_currency_amount_disburse_sample.csv"
                download
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#8E173E",
                  marginBottom: "10px",
                }}
              >
                <Download style={{ fontSize: "medium" }} />{" "}
                <p style={{ margin: "0px" }}>
                  {" "}
                  Get a sample bulk disburse CSV file{" "}
                </p>
              </a>{" "}
            </span>
            <div className={styles.modalselect}>
              <label className={styles.inputLabel}>Select Currency</label>
              <Select
                defaultValue={selectedCurrencyInput}
                onChange={setSelectedCurrencyInput}
                options={currenciesOption}
                className={styles.select}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    width: "100%",
                  }),
                }}
              />
            </div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "gray",
                gap: "4",
                margin: "5px 0px",
              }}
            >
              {" "}
              <IoInformationCircle />{" "}
              <p style={{ margin: "0px 5px 0px", padding: "0" }}>
                For a list of banks and their tags, click here to{" "}
                <a
                  href="/../../../../src/assets/documentSamples/Bank_list_1738713600065.csv"
                  download
                  style={{ color: "#8e173e" }}
                >
                  {" "}
                  download
                </a>{" "}
              </p>{" "}
            </span>
            <FileUpload
              handleFileDrop={(file) => setFileUploaded(file)}
              onChange={(e) => {
                const file = e?.target?.files?.[0];
                if (file) {
                  setFileUploaded(file);
                }
              }}
              accept=".csv, text/csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ></FileUpload>
            {fileUploaded && (
              <span style={{ display: "flex" }}>
                <p>{fileUploaded.name}</p>
                <button
                  onClick={() => setFileUploaded(null)}
                  style={{
                    border: "1px solid red",
                    borderRadius: "100%",
                    padding: "5px",
                  }}
                >
                  <Delete
                    style={{ fontSize: "small", marginLeft: "10px" }}
                  ></Delete>
                </button>
              </span>
            )}
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Initiate payout
              </div>
            ) : (
              <div className={styles.notClickable}> Initiate payout</div>
            )}
          </div>
        </>
      )}
      <Modal
        isOpen={modalIsOpen.isOpened}
        onRequestClose={closeModal}
        contentLabel="Verify Payout"
        style={customStyles}
      >
        <PayoutVerificationModal
          data={modalIsOpen.data}
          closeModal={closePayoutVerificationModal}
        />
      </Modal>
    </div>
  );
};

export default InitiatePayout;
