import React, { useState, Fragment, useEffect } from "react";
import styles from "./RollingReserve.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  RollingReserveData,
  ColumnRollingReserve,
} from "../../types/TablesTypes/rollingReserve";
import TableHeader from "../../components/TableHeader/TableHeader";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import moment from "moment";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import { ToastErrorStyles } from "../../constants/toastStyles";
import BalanceMenu from "../../components/MenuOption/BalanceMenu/BalanceMenu";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import StatusView from "../../components/StatusView/StatusView";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { setTimeOnDate } from "../../utils";

const RollingReserve = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const { currency } = useSelector((state) => state.appSettingsReducer);
  const dispatch = useDispatch();

  function closeModal() {
    setIsOpen(false);
  }

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        // { value: "", name: "All" },
        { name: "Almost due" },
        { name: "Due" },
        { name: "Not due" },
      ],
    },
    // {
    //   id: 2,
    //   name: "Payment Reference",
    //   value: paymentreference,
    //   setValue: setPaymentReference,
    // },
  ];

  const fetchRollingReserve = () => {
    dispatch(openLoader());

    let queryParams = new URLSearchParams();

    if (currency) queryParams.append("currency", currency);
    if (status) queryParams.append("status", status);
    if (fromDate)
      queryParams.append("fromdate", setTimeOnDate(fromDate, 0, 0, 0));
    if (toDate) queryParams.append("todate", setTimeOnDate(toDate, 23, 59, 59));

    client
      // .get(`/subsidiary/dashboard/rolling/reserves?currency=${currency}&status=${status}&fromdate=${fromDate}&todate=${toDate}`)
      .get(`/subsidiary/dashboard/rolling/reserves?${queryParams.toString()}`)
      .then((res: any) => {
        setCustomerDetails(res.data);
        dispatch(closeLoader());
        setBearer(false);
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };
  useEffect(() => {
    fetchRollingReserve();
  }, [currency, bearer]);

  const dataPaymentLinks = () => {
    const tempArr: RollingReserveData[] = [];
    customerDetails?.data?.items.forEach((balance: any, index: number) => {
      tempArr.push({
        settlementDate: moment(balance?.settlementDate).format(
          "MMMM Do YYYY, h:mm a"
        ),
        dueDate: moment(balance?.settlementDate).format("MMMM Do YYYY, h:mm a"),
        settlementAmount: `${currency} ${numberWithCommas(
          balance?.settlementAmount
        )}`,
        initialAmount: `${currency} ${numberWithCommas(
          balance?.settlementAmount - balance?.withheldAmount
        )}`,
        withheldAmount: `${currency} ${numberWithCommas(
          balance?.withheldAmount
        )}`,
        id: balance?.id,
        status: (
          <StatusView
            status={balance?.status}
            green={"Completed"}
            red={"Due"}
          />
        ),
        balance: `${currency} ${numberWithCommas(balance?.balance)}`,
        action: <BalanceMenu data={balance} />,
      });
    });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Rolling Reserve"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.page?.total}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            {/* <Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							direct={true}
						/> */}
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      {/* <Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<CreatePaymentLink
					closeModal={closeModal}
					fetchPaymentLink={fetchBalanceHistory}
				/>
			</Modal> */}
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnRollingReserve ? ColumnRollingReserve : []}
          emptyPlaceHolder="You currently do not have any rolling reserve"
          total={customerDetails?.data?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default RollingReserve;
