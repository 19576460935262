import React, { useState, Fragment, useEffect } from "react";
import styles from "./fundinghistory.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { FundingHistoryData } from "../../types/TablesTypes/fundingHistory";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import Mocked from "./fundinghistory.json";
import PendingApprovalMenu from "../../components/MenuOption/pendingApproval/PendingApproval";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import { ReactComponent as CloseIcon } from "../../assets/images/Icons/closenew.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/Icons/u_copy.svg";
import Select from "react-select";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import { Divider } from "antd";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { Pagination } from "../../components/Pagination/Pagination";
import Table from "../../components/table";
import { setTimeOnDate } from "../../utils";

const Fundinghistory = () => {
  const ColumnFundingHistory = [
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }: any) => (
        <div style={{ whiteSpace: "nowrap" }}>{value}</div>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }: any) => (
        <div style={{ whiteSpace: "nowrap" }}>{value}</div>
      ),
    },
    {
      Header: "Account Number",
      accessor: "accountNumber",
    },
    {
      Header: "Account Alias",
      accessor: "accountAlias",
    },
    {
      Header: "Method",
      accessor: "method",
    },
    {
      Header: "Session ID",
      accessor: "transactionReference",
    },
    {
      Header: "Status",
      accessorKey: "status",
      Cell: () => <span className={styles.transactionStatus}>Successful</span>,
    },
    {
      Header: "Fee",
      accessor: "fee",
    },
    // {
    // 	Header: '',
    // 	accessor: 'action',
    // },
  ];

  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const paymentTypes: any[] = [];
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();
  const [inputs, setInputs] = React.useState<string>("");
  const [funding, setFunding] = useState<any>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  let selectedPaymentTypeOptions = paymentTypes.map(function (
    selectedPaymentType: any
  ) {
    return {
      value: selectedPaymentType.code,
      label: selectedPaymentType.paymentLinkName,
    };
  });
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs(event.target.value);
  };

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [sessionID, setsessionID] = useState<string>("");
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setsessionID("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    // {
    //   id: 1,
    //   name: "Status",
    //   value: status,
    //   setValue: setStatus,
    //   selective: [
    //     { name: "Successful" },
    //     { name: "Initiated" },
    //     { name: "Awaiting-Confirmation" },
    //   ],
    // },
    // {
    //   id: 2,
    //   name: "Payment Reference",
    //   value: paymentreference,
    //   setValue: setPaymentReference,
    // },
    {
      id: 2,
      name: "Session ID",
      value: sessionID,
      setValue: setsessionID,
    },
    // {
    //   id: 3,
    //   name: "Order Reference",
    //   value: orderreference,
    //   setValue: setOrderReference,
    // },
  ];
  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  useEffect(() => {
    setBearer(true);
  }, [page, pageSize]);

  const fetchFunding = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/funding/history?page=${page}&size=${pageSize}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${
          toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""
        }&sessionId=${sessionID}`
      );
      setFunding(data?.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchFunding();
  }, [bearer]);

  const dataTransaction = () => {
    const tempArr: FundingHistoryData[] = [];
    funding?.items?.slice(0).forEach((transaction: any, index: number) => {
      tempArr.push({
        amount: `${transaction.currency} ${numberWithCommas(
          transaction.amount
        )}`,
        accountAlias: transaction.accountAlias,
        accountNumber: transaction.accountNumber,
        method: transaction.method ?? "N/A",
        transactionReference: transaction.transactionReference ?? "N/A",
        status: "Successful",
        fee: transaction.fee,
        date: moment(transaction?.dateCreated).format("DD MMM YYYY hh:mm:ss a"),
        action: <PendingApprovalMenu />,
      });
    });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [funding?.items]);

  const handleExport = async () => {
    dispatch(openLoader());
    try {
      const totalPages = funding?.page?.totalPage;

      if (!totalPages) {
        return [];
      }
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/funding/history?page=${1}&size=1&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${
          toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""
        }&sessionId=${sessionID}&export=true`
      );

      return [...data?.data?.items];
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(closeLoader());
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePagSizeChange = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  return (
    <Fragment>
      <TableHeader
        pageName="Funding History"
        dataLength={funding?.page?.total}
        newButton={<div></div>}
        data={funding?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        filtering={true}
        otherbutton={false}
        otherbuttonTitle="Fund balance"
        otherbuttonfunction={setIsOpen}
        handleFetchData={handleExport}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          <Table
            data={tableRow ? tableRow : []}
            // value={value}
            columns={ColumnFundingHistory ? ColumnFundingHistory : []}
            // emptyPlaceHolder="You currently do not have any data"
            // total={funding?.totalPages}
          />
          <div style={{ marginBlock: 15 }}>
            <Pagination
              totalPages={funding?.page?.totalPage ?? 0}
              currentPage={page}
              handlePageChange={handlePageChange}
              pageSize={pageSize}
              handlePageSizeChange={handlePagSizeChange}
            />
          </div>
        </>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Fund Balance</h3>
            <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div className={styles.modalcontent}>
            <p className={styles.modalcontentp}>
              To fund your naira wallet, make a transfer to the account details
              below
            </p>

            <div className={styles.modalcontentbox}>
              <div className={styles.modalcontentboxflex}>
                <p className={styles.modalcontentboxp}>Bank name:</p>
                <p className={styles.modalcontentboxp}>Wema Bank Plc</p>
              </div>
              <div className={styles.modalcontentboxflex}>
                <p className={styles.modalcontentboxp}>Account number:</p>
                <p className={styles.modalcontentboxp}>
                  1839582940{" "}
                  <span>
                    <CopyIcon />
                  </span>
                </p>
              </div>
              <div className={styles.modalcontentboxflex}>
                <p className={styles.modalcontentboxp}>Account name:</p>
                <p className={styles.modalcontentboxp}>Transactpay Limited</p>
              </div>
            </div>

            <div className={styles.modalselect}>
              <label className={styles.inputLabel}>
                Which balance do you want to fund?
              </label>
              <Select
                defaultValue={selectedPaymentType}
                onChange={setSelectedPaymentType}
                options={selectedPaymentTypeOptions}
                // styles={customStyles}
                className={styles.select}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    width: "100%",
                  }),
                }}
              />
            </div>
            <div className={styles.input}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="Funding amount"
                inputLabel="Funding amount"
                inputValue={inputs}
                style={{ width: "100%" }}
              />
            </div>

            <Divider style={{ margin: 0, padding: 0 }} />

            <div className={styles.modalFooter}>
              <button className={styles.cancel} onClick={closeModal}>
                Cancel
              </button>

              <button className={styles.fund}>Fund</button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Fundinghistory;
