import { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { TransactionsData } from "../../types/TablesTypes/Transactions";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { setTimeOnDate } from "../../utils";
import Table from "../../components/table";
import { Pagination } from "../../components/Pagination/Pagination";

const Transactions = ({ eTransactions, cb }: any) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [transactions, setTransactions] = useState<any>(eTransactions);
  const { currency } = useSelector((state) => state.appSettingsReducer);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [transactionType, settransactionType] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    settransactionType("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { value: "", name: "All" },
        { name: "Initiated" },
        { name: "Awaiting-Confirmation" },
        { name: "Pending" },
        { name: "Successful" },
        { name: "Reversed" },
        { name: "Failed" },
        { name: "Processing" },
      ],
    },
    {
      id: 2,
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
    },
    {
      id: 3,
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
    },
    {
      id: 4,
      name: "Transaction Type",
      value: transactionType,
      setValue: settransactionType,
      selective: [
        { value: "", name: "All" },
        { name: "Card" },
        { name: "Transfer" },
      ],
    },
  ];
  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const ColumnTransactions = [
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }: any) => (
        <div style={{ whiteSpace: "nowrap" }}>{value}</div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Recurring Frequency",
      accessor: "recurringFrequency",
    },
    {
      Header: "Settlement Amount",
      accessor: "settlementAmount",
    },
    {
      Header: "Payment method",
      accessor: "payment",
    },
    {
      Header: "Reference",
      accessor: "reference",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  useEffect(() => {
    setBearer(true);
  }, [pageSize, page]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const fetchTransactions = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/orders?currency=${currency}&limit=${pageSize}&page=${page}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&transactionType=${transactionType}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      );
      setTransactions(data?.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!eTransactions) fetchTransactions();
  }, [currency, bearer]);

  const handleExport = async () => {
    dispatch(openLoader());
    try {
      const totalPages = transactions?.page?.totalPage;

      if (!totalPages) {
        return [];
      }
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/orders?currency=${currency}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${
          toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""
        }&isDownload=true&limit=5000`
      );

      return [...data?.data?.items];
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(closeLoader());
    }
  };

  const dataTransaction = () => {
    const tempArr: TransactionsData[] = [];
    (eTransactions ?? transactions)?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        const amount = Number(
          transaction?.amountFormatted ?? transaction?.transactionAmount
        );
        const fee = Number(transaction?.fee ?? transaction?.fee);
        const settlementAmount = amount - fee;
        tempArr.push({
          date: moment(transaction?.dateCreated).format(
            "DD-MMM-YYYY hh:mm:ss a"
          ),
          // email: transaction?.customerEmail,
          email:
            transaction?.customerEmail ??
            transaction?.order?.customer?.emailAddress,
          recurringFrequency: transaction?.recurringFrequency,
          amount: `${
            transaction?.currency?.shortName ?? transaction?.currency
          } ${numberWithCommas(amount)}`,
          settlementAmount: `${
            transaction?.currency?.shortName ?? transaction?.currency
          } ${numberWithCommas(settlementAmount)}`,
          payment:
            transaction?.paymentTypeName ??
            transaction?.order?.paymentTypeName ??
            transaction?.order?.paymentType ??
            "NIL",
          reference:
            transaction?.orderReference ?? transaction?.transactionReference,
          status: (
            <StatusView
              status={transaction?.orderStatus ?? transaction?.status?.name}
              green="Successful"
              red="Awaiting-Confirmation"
              orange="Initiated"
            />
          ),
          action: (
            <TransactionsMenu
              currency={
                transaction?.currency?.shortName ?? transaction?.currency
              }
              transactionId={transaction?.id}
              fetchTransactions={fetchTransactions}
              amount={Number(transaction?.amountFormatted)}
              customerId={transaction?.customerId}
              status={transaction?.orderStatus}
              reference={transaction?.paymentReference}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [transactions?.items, eTransactions?.items]);

  return (
    <Fragment>
      <TableHeader
        pageName="Transactions"
        dataLength={(eTransactions ?? transactions)?.page?.total}
        data={(eTransactions ?? transactions)?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        // fetchExportDataHandler={async () => eTransactions ? (cb && cb(true)) : await fetchTransactions(true)}
        newButton={<div></div>}
        handleFetchData={handleExport}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          <Table
            data={tableRow ? tableRow : []}
            // value={value}
            columns={ColumnTransactions ? ColumnTransactions : []}
            // emptyPlaceHolder="You currently do not have any transactions"
            // total={(eTransactions ?? transactions)?.page?.total}
          />
          <div>
            <Pagination
              pageSize={pageSize}
              currentPage={page}
              totalPages={transactions?.page?.totalPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Transactions;
