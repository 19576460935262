import { lazy, Suspense } from "react";
import LoaderPage from "../../../components/LoaderPage";

const BulkPayoutComponent = lazy(() => import("./BulkPayout"));

const BulkPayoutPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <BulkPayoutComponent />
  </Suspense>
);

export default BulkPayoutPage;
