import React, { useState, useEffect } from "react";
import styles from "./Customerdetails.module.scss";
import { useParams } from "react-router";
import axios from "axios";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import { useHistory } from "react-router-dom";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnTransactions,
  TransactionsData,
} from "../../types/TablesTypes/Transactions";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import moment from "moment";
import { numberWithCommas } from "../../utils/formatNumber";

const CustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState<string>("");
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();

  //for filtering
  const [valueChannel, setValueChannel] = useState("");
  const [valueStatus, setValueStatus] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [transactions, setTransactions] = useState<any>();

  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const fetchTransactions = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/customers/orders?customerid=${id}`
      );
      setTransactions(data.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [valueStatus]);

  const dataTransaction = () => {
    const tempArr: TransactionsData[] = [];
    transactions?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          date: moment(transaction?.dateCreated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          email: transaction?.customerEmail,
          recurringFrequency: transaction?.recurringFrequency,
          amount: `${transaction.currency} ${numberWithCommas(
            transaction?.amount
          )}`,
          payment: transaction?.paymentTypeName || "N/A",
          reference: transaction?.orderReference,
          settlementAmount: `${transaction.currency} ${numberWithCommas(
            transaction?.settlementAmount
          )}`,
          status: (
            <StatusView
              status={transaction?.orderStatus}
              green="Successful"
              red="Awaiting-Confirmation"
              orange="Initiated"
            />
          ),
          action: (
            <TransactionsMenu
              currency={transaction?.currency ?? "NGN"}
              transactionId={transaction?.id}
              fetchTransactions={fetchTransactions}
              amount={transaction?.settlementAmount}
              customerId={transaction?.customerId}
              status={transaction?.orderStatus}
              reference={transaction?.paymentReference}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [transactions?.items]);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/subsidiary/dashboard/fetch/customers?id=${id}`)
      .then((res: any) => {
        setCustomerDetails(res?.data?.items[0]);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <>
          <div className={styles.detailBox}>
            <h3 className={styles.detailh3}>Customer Details</h3>
            <Divider />
            <div style={{ padding: "0 24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2} lg={3}>
                  <div className={styles.customerInfo}>
                    <div className={styles.detailsValue}>Customer Name</div>
                    <div className={styles.detailsKey}>
                      {customerDetails?.customerName}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div className={styles.customerInfo}>
                    <div className={styles.detailsValue}>Customer email</div>
                    <div className={styles.detailsKey}>
                      {customerDetails?.emailAddress}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={3}>
                  <div className={styles.customerInfo}>
                    <div className={styles.detailsValue}>Mobile phone</div>
                    <div className={styles.detailsKey}>
                      {customerDetails?.mobileNumber}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div className={styles.customerInfo}>
                    <div className={styles.detailsValue}>Country</div>
                    <div className={styles.detailsKey}>
                      {customerDetails?.countryName}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div>
            <TableHeader
              pageName="Transactions"
              dataLength={transactions?.totalItems}
              data={transactions?.items}
              value={value}
              setValue={setValue}
              dropdown={dropdown}
              setDropdown={setDropdown}
              newButton={<div></div>}
              FilterComponent={
                <>
                  <Filtered
                    menuContent={menuContent}
                    dropdown={dropdown}
                    setDropdown={setDropdown}
                    valueChannel={valueChannel}
                    setValueChannel={setValueChannel}
                    valueStatus={valueStatus}
                    setValueStatus={setValueStatus}
                    status={true}
                    channel={true}
                  />
                </>
              }
            />

            {getTransactionsApi.loading ? (
              <div className={styles.loading} />
            ) : (
              <PaginationTable
                data={tableRow ? tableRow : []}
                value={value}
                columns={ColumnTransactions ? ColumnTransactions : []}
                emptyPlaceHolder="You currently do not have any transactions"
                total={transactions?.totalItems}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
