import React, { useState, Fragment, useEffect } from "react";
import styles from "./Payoutaccounts.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnPayoutsNew,
  PayoutsDataNew,
} from "../../types/TablesTypes/Payouts";
import TableHeader from "../../components/TableHeader/TableHeader";
import Radio from "@mui/material/Radio";
import Modal from "react-modal";
import { ReactComponent as LinkIcon } from "../../assets/IconsMerchant/u_building.svg";
import { customStyles } from "../../constants/modalStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import AddBank from "../beneficiary/AddBank";
import { StarRate } from "@mui/icons-material";

const PayoutAccounts = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [transactions, setTransactions] = useState<any>();
  const [selectedValue, setSelectedValue] = React.useState("");
  const [isDefaultClicked, setisDefaultClicked] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [subb_id, setsubb_id] = useState("");
  //get current subsidiary Id
  const { activeSubsidiary } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  function closeDefaultModal() {
    setisDefaultClicked(false);
  }

  const dispatch = useDispatch();

  const fetchPayment = async () => {
    try {
      const data: any = await client.get(
        "/subsidiary/dashboard/settlement/details/get"
      );
      setSelectedValue(data?.data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  function closeModal() {
    setIsOpen(false);
    setSelectedAccount(null);
  }

  const fetchTransactions = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/settlement/bank/get`
      );
      setTransactions(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchTransactions();
    fetchPayment();
  }, []);

  const SetDefaultAccount = (account: any) => {
    setSelectedAccount(account);
    setisDefaultClicked(true);
  };

  useEffect(() => {
    if (activeSubsidiary) {
      setsubb_id(activeSubsidiary.id);
    }
  }, [activeSubsidiary]);

  const setDefaultAccountFunction = async () => {
    if (!selectedAccount) return;
    dispatch(openLoader());
    try {
      await client.put(
        `/subsidiary/dashboard/settlement/bank/default/set/${selectedAccount.id}`
        // null, {headers: {'subb_id': subb_id,}}
      );
      dispatch(
        openToastAndSetContent({
          toastContent: "Default account set successfully!",
          toastStyles: ToastSuccessStyles,
        })
      );
      fetchTransactions();
      closeDefaultModal();
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  const dataPayout = () => {
    const tempArr: PayoutsDataNew[] = [];
    transactions?.data
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          bankName: payout.bankName,
          accountNumber: payout?.accountNumber,
          accountName: payout?.accountName,
          action: payout.isDefault ? (
            // <div
            //   style={{
            //     maxWidth: "120px",
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //   }}
            // >
            //   <StarRate
            //     style={{
            //       color: "gold",
            //       fontSize: "1.5rem",
            //     }}
            //   />
            // </div>
            <div className={styles.defaultIdentifier}>Default</div>
          ) : (
            <button
              style={{ backgroundColor: "#8E173E" }}
              className={styles.setdefaultbutton}
              onClick={() => SetDefaultAccount(payout)}
            >
              Set Default
            </button>
          ),
          // action: ( <button style={{backgroundColor: "#8E173E"}} className={styles.setdefaultbutton} onClick={() => SetDefaultAccount(payout)}> Set Default </button> )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPayout());
  }, [transactions?.data]);

  interface initTypes {
    wallet?: string;
    bank?: string;
  }

  const initProps: initTypes = {
    wallet: "wallet",
    bank: "bank",
  };

  const [inputs, setInputs] = React.useState(initProps);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/settlement/details", {
        settlementType: event.target.value,
      })
      .then((data: any) => {
        fetchPayment();
        dispatch(closeLoader());

        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        const message = error?.response?.data?.message;
        dispatch(closeLoader());

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  return (
    <Fragment>
      <div className={styles.accounts}>
        <div className={styles.accountInfo}>Accounts</div>
        <div className={styles.accountEarnings}>
          Which account would you like to get your earnings?
        </div>
        <div className={styles.radioSelection_one}>
          <Radio
            // checked={selectedValue === inputs.wallet || true}
            checked={selectedValue === inputs.wallet}
            onChange={handleChange}
            value={inputs.wallet}
            name="bank"
            inputProps={{ "aria-label": inputs.wallet }}
            size="small"
          />
          <div className={styles.radioLabel}>
            Settle to Transactpay account{" "}
          </div>
        </div>
        <div className={styles.radioSelection}>
          <Radio
            checked={selectedValue === inputs.bank}
            onChange={handleChange}
            value={inputs.bank}
            name="wallet"
            inputProps={{ "aria-label": inputs.bank }}
            size="small"
          />
          <div className={styles.radioLabel}>Settle to bank </div>
        </div>
      </div>

      <div
        style={{
          opacity: selectedValue === "Bank" ? "0.3" : "1",
          pointerEvents: selectedValue === "Bank" ? "none" : "auto",
        }}
      >
        <TableHeader
          pageName="Bank accounts"
          dataLength={transactions?.data?.length}
          data={transactions?.data}
          filtering={false}
          newButton={
            <div className={styles.createLink} onClick={() => setIsOpen(true)}>
              <LinkIcon className={styles.link} />
              Add a bank account
            </div>
          }
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <AddBank fn={fetchTransactions} closeModal={closeModal} />
        </Modal>

        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnPayoutsNew ? ColumnPayoutsNew : []}
          emptyPlaceHolder="You currently do not have any payout accounts"
          recent={true}
        />
      </div>

      {isDefaultClicked && (
        <Modal
          isOpen={isDefaultClicked}
          onRequestClose={closeDefaultModal}
          contentLabel="Set Default Account"
          style={customStyles}
        >
          <div className={styles.modalContent}>
            <h2 className={styles.modalHeader}>Set Default Account</h2>
            <p>
              You are about to set the account below as your default settlement
              account:
            </p>
            {selectedAccount && (
              <div className={styles.accountDetails}>
                <p>
                  <strong>Bank Name:</strong> {selectedAccount.bankName}
                </p>
                <p>
                  <strong>Account Number:</strong>{" "}
                  {selectedAccount.accountNumber}
                </p>
                <p>
                  <strong>Account Name:</strong> {selectedAccount.accountName}
                </p>
              </div>
            )}
            <p>Do you wish to continue?</p>
            <div className={styles.modalButtons}>
              <button
                style={{ backgroundColor: "#006400" }}
                className={styles.setdefaultbutton}
                onClick={() => {
                  setDefaultAccountFunction();
                  closeDefaultModal();
                }}
              >
                Yes
              </button>
              <button
                style={{ backgroundColor: "#BA0021" }}
                className={styles.setdefaultbutton}
                onClick={closeDefaultModal}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default PayoutAccounts;
