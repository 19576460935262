import React, { Component, ComponentProps, useState } from 'react'
import styles from "./FileUpload.module.scss";
import uploadIcon from "../../assets/images/Icons/upload-icon.svg";

type Props = {
    MaxSize? : string;
    handleFileDrop?: (file: File) => void;
} & ComponentProps<'input'>

const FileUpload:React.FC<Props> = ({MaxSize, handleFileDrop, ...props}) => {

    const [dragging, setDragging] = useState(false);

    const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const file = e?.dataTransfer?.files?.[0]
        if (file && handleFileDrop) {
            handleFileDrop(file)
        }
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

  return (
    <>
        <div>
            <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <label
                    htmlFor="product-image"
                    className={`${styles.uploadBox} ${
                        dragging ? styles.dragging : ""
                    }`}
                    >
                    <img src={uploadIcon} alt="Upload" />
                    <p>
                        Upload File {MaxSize && (<span>{MaxSize}</span>) }
                    </p>
                </label>
            </div>
            <input
                type="file"
                id="product-image"
                hidden
                {...props}
            />
        </div>    
    </>
  )
}

export default FileUpload