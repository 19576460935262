import React, { useState, Fragment, useEffect } from "react";
import styles from "./Settlements.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnSettlementGrouped,
  ColumnSettlements,
  SettlementGroupedData,
  SettlementsData,
} from "../../types/TablesTypes/Settlements";
import ExportButton from "../../components/ExportButton/ExportButton";
import StatusView from "../../components/StatusView/StatusView";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import settlementsApi from "../../api/settlements";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import SettlementMenu from "../../components/MenuOption/SettlementMenu/SettlementMenu";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import moment from "moment";
import { setTimeOnDate } from "../../utils";

const SettlementGrouped = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [settlements, setSettlements] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getSettlementsApi = useApi(settlementsApi.getSettlements);
  const { currency } = useSelector((state) => state.appSettingsReducer);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Completed" },
        { name: "Partial-Completed" },
        { name: "Pending" },
        { name: "Flagged" },
      ],
    },
  ];

  const fetchSettlement = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/settlement/grouped?currency=${currency}&settlementstatus=${status}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      );
      setSettlements(data?.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchSettlement();
  }, [bearer, currency]);

  const dataTransaction = () => {
    const tempArr: SettlementGroupedData[] = [];
    settlements?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        tempArr.push({
          subsidiaryName: settlement?.subsidiaryName,
          settlementAccount: settlement?.settlementAccount,
          settlementBankName: settlement?.settlementBankName,
          settlementBankCode: settlement?.settlementBankCode,
          settlementDestination: settlement?.settlementDestination,
          transactionCount: settlement?.transactionCount,
          settlementAmount: `${settlement?.currency} ${numberWithCommas(
            settlement?.settlementAmount
          )}`,
          // fee: `${settlement?.currency} ${numberWithCommas(settlement?.fee)}`,
          finalSettlementDate: moment(settlement?.finalSettlementDate).format(
            "DD-MMM-YYYY hh:mm:ss a"
          ),
          currency: `${(settlement?.currency).toUpperCase()}`,
          settlementStatus: (
            <StatusView
              status={settlement?.settlementStatus}
              green="Completed"
              orange="Pending"
            />
          ),
          action: (
            <SettlementMenu isTransactionSummary={true} data={settlement} />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [settlements?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Settlement"
        dataLength={settlements?.page?.total}
        data={settlements?.items}
        value={value}
        setValue={setValue}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      {getSettlementsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnSettlementGrouped ? ColumnSettlementGrouped : []}
          emptyPlaceHolder="You currently do not have any settlements"
          total={settlements?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default SettlementGrouped;
