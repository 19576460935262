type CurrencyOptionType = {
  name: string;
  shortName: string;
  isAllowed: boolean;
  id: number;
};

type CurrencySelectType = {
  id: number;
  value: string;
  shortName: string;
  label: string;
};

export const formattCurrencyOptionsForSelect = (
  currencies: CurrencyOptionType[],
  defaultCurrency: string
) => {
  if (!currencies || !defaultCurrency) {
    return [];
  }
  // Format the currency
  const formattedCurrencies = currencies?.map((cur: any) => ({
    id: cur.id,
    value: cur.id,
    label: `${cur.name} (${cur.shortName})`,
    shortName: cur.shortName,
  }));

  // Pick out the default curreny so we can append it to the end of the list
  const defaultCurrencyOpt = formattedCurrencies.find(
    (cur: any) => cur.shortName.toUpperCase() === defaultCurrency.toUpperCase()
  );
  const filterCurrencies = formattedCurrencies.filter(
    (cur) => cur.shortName.toUpperCase() !== defaultCurrency.toUpperCase()
  );
  if (defaultCurrencyOpt) {
    filterCurrencies.unshift(defaultCurrencyOpt);
  }
  return filterCurrencies;
};

export const formattCurrencyOptionsForSelectWithPriorities = (
  currencies: CurrencyOptionType[],
  priorityCurrencyShortNameList: string[]
) => {
  const uniqueShortNames = Array.from(
    new Set(
      priorityCurrencyShortNameList.map((curr) => curr.toUpperCase())
    ).values()
  );
  let formattedCurrencies = currencies?.map((cur: any) => ({
    id: cur.id,
    value: cur.id,
    label: `${cur.name} (${cur.shortName})`,
    shortName: cur.shortName,
  }));
  const priorityCurrencies: CurrencySelectType[] = [];

  for (const currency of uniqueShortNames) {
    const currencyOpt = formattedCurrencies.find(
      (cur: any) => cur.shortName.toUpperCase() === currency.toUpperCase()
    );
    if (currencyOpt) {
      priorityCurrencies.push(currencyOpt);
      formattedCurrencies = formattedCurrencies.filter(
        (cur) => cur.shortName.toUpperCase() !== currency.toUpperCase()
      );
    }
  }
  formattedCurrencies = [...priorityCurrencies, ...formattedCurrencies];
  return formattedCurrencies;
};
