import React from "react";
import styles from "./Pagination.module.scss";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { Select } from "antd";

const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];

type Props = {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  pageSize?: number;
  handlePageSizeChange?: (size: number) => void;
};

export const Pagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  handlePageChange,
  pageSize,
  handlePageSizeChange,
}) => {
  const formattedPageSelectionOptions = Array.from(
    { length: totalPages },
    (_, i) => i + 1
  ).map((num) => ({
    id: num,
    value: num,
    label: `${num} of ${totalPages}`,
  }));

  return (
    <div className={styles.wrapper}>
      <button
        disabled={currentPage <= 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <IoMdArrowBack className={styles.icon} />
        <span className={styles.directionText}>Previous</span>
      </button>
      <p>
        {" "}
        {currentPage} <strong>of</strong> {totalPages}
      </p>
      {pageSize && handlePageSizeChange ? (
        <Select
          options={PAGE_SIZE_OPTIONS.map((value) => ({
            id: value,
            label: value,
            value: value,
          }))}
          style={{ minWidth: 100 }}
          defaultValue={pageSize}
          onChange={(value) => {
            handlePageSizeChange(value);
          }}
        />
      ) : (
        <Select
          options={formattedPageSelectionOptions}
          style={{ minWidth: 100 }}
          defaultValue={1}
          onChange={(value) => {
            handlePageChange(value);
          }}
        />
      )}
      <button
        disabled={currentPage >= totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        className={styles.directionText}
      >
        <span className={styles.directionText}>Next</span>{" "}
        <IoMdArrowForward className={styles.icon} />
      </button>
    </div>
  );
};
