import React, { useState, Fragment, useEffect } from "react";
import styles from "./Subsidiary.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  ColumnSubsidiaries,
  SubsidiariesData,
} from "../../types/TablesTypes/Subsidiaries";
import ExportButton from "../../components/ExportButton/ExportButton";
import subsidiariesApi from "../../api/subsidiaries";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { ReactComponent as TopUp } from "../../assets/images/Icons/topup.svg";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import AddNewSub from "../../components/ModalContent/AddNewSub/AddNewSub";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import StatusView from "../../components/StatusView/StatusView";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";

const Subsidiary = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  // const [sub, setSub] = useState<any>();
  const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setEmail("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Pending" },
        { name: "Not Started" },
        { name: "Approved" },
        { name: "Declined" },
      ],
    },
    {
      id: 2,
      name: "Email Address",
      value: email,
      setValue: setEmail,
    },
  ];

  // const subsidiary = async () => {
  // 	dispatch(openLoader());
  // 	try {
  // 		const { data } = await client.get(
  // 			`/subsidiary/dashboard/users/subsidiaries?status=${status}&fromdate=${fromDate}&todate=${toDate}`
  // 		);
  // 		setSub(data);
  // 		dispatch(closeLoader());
  // 		setBearer(false);
  // 	} catch (error: any) {
  // 		dispatch(closeLoader());
  // 		if (error?.response?.data?.message === 'Please login again') {
  // 			sessionStorage.clear();
  // 			window.location.href = '/';
  // 		} else {
  // 			const message = error?.response?.data?.message;

  // 			dispatch(
  // 				openToastAndSetContent({
  // 					toastContent: message,
  // 					toastStyles: ToastErrorStyles,
  // 				})
  // 			);
  // 		}
  // 	}
  // };

  useEffect(() => {
    getSubsidiariesApi.request();
  }, [bearer]);

  const dataPaymentLinks = () => {
    const tempArr: SubsidiariesData[] = [];
    getSubsidiariesApi?.data?.subsidiaryDetails?.subsidiaries
      ?.slice(0)
      .reverse()
      .forEach((subsidiary: any, index: number) => {
        tempArr.push({
          businessName: subsidiary?.businessName,
          supportEmail: subsidiary?.supportEmail,
          country: subsidiary?.country,
          status: (
            <StatusView
              status={subsidiary?.verificationStatus}
              green="Active"
              red="Declined"
              orange="Pending-Approval"
              indigo="Pending-Verification"
              blue="New"
            />
          ),
          businessType: subsidiary?.businessType,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [getSubsidiariesApi?.data]);

  return (
    <Fragment>
      <TableHeader
        pageName="Subsidiaries"
        dataLength={getSubsidiariesApi?.data?.subsidiaryDetails?.total}
        data={getSubsidiariesApi?.data?.subsidiaryDetails?.subsidiaries}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <TopUp className={styles.link} />
            Create new subsidiary
          </div>
        }
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      {getSubsidiariesApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnSubsidiaries ? ColumnSubsidiaries : []}
          emptyPlaceHolder="You currently do not have any subsidiaries"
          total={getSubsidiariesApi?.data?.subsidiaryDetails?.total}
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <AddNewSub closeModal={closeModal} />
      </Modal>
    </Fragment>
  );
};

export default Subsidiary;
