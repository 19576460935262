import React, { useState, Fragment, useEffect } from "react";
import styles from "./Paymentlinks.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnPaymentLinks,
  PaymentLinksData,
} from "../../types/TablesTypes/PaymentLinks";
import TableHeader from "../../components/TableHeader/TableHeader";
import MenuOption from "../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import CopyIcon from "../../assets/images/Icons/copy.svg";
import paymentLinksApi from "../../api/paymentLinks";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { computeCustomStyles, customStyles } from "../../constants/modalStyles";
import PaymentLinkMenu from "../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import CreatePaymentLink from "../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { ButtonTab } from "../../components/Tabs/Tabs";
import { capitalize, setTimeOnDate } from "../../utils";

const LINK_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
};

const PAYMENT_LINK_STATUS_OPTIONS = [
  {
    id: 1,
    title: "All Links",
    value: "",
  },
  {
    id: 2,
    title: "Active Links",
    value: LINK_STATUS.ACTIVE,
  },
  {
    id: 3,
    title: "Deactivated Links",
    value: LINK_STATUS.INACTIVE,
  },
];

const PaymentLinks = () => {
  const dispatch = useDispatch();
  const [paymentLinks, setPaymentLinks] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const getPaymentLinksApi = useApi(paymentLinksApi.getPaymentLinks);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [{ name: "InActive" }, { name: "Active" }],
    },
  ];

  const fetchPaymentLink = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/payment/link/subsidiary?status=${status}&fromdate=${
          fromDate ? setTimeOnDate(fromDate, 0, 0, 0) : ""
        }&todate=${toDate ? setTimeOnDate(toDate, 23, 59, 59) : ""}`
      );
      setPaymentLinks(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchPaymentLink();
  }, [bearer]);

  const StatusView = ({ status, green, orange, red }: any) => {
    return (
      <div>
        {status === red ? (
          <div className={styles.declined}>Inactive</div>
        ) : status === orange ? (
          <div className={styles.processing}>{orange}</div>
        ) : status === green ? (
          <div className={styles.completed}>Active</div>
        ) : null}
      </div>
    );
  };

  const dataPaymentLinks = () => {
    const tempArr: PaymentLinksData[] = [];
    paymentLinks?.items
      ?.slice(0)
      .reverse()
      .forEach((paymentLink: any, index: number) => {
        tempArr.push({
          name: paymentLink?.name,
          paymentType: paymentLink?.paymentType,
          limit: paymentLink?.limit ?? "No limit",
          currency: paymentLink?.currency,
          authOption: paymentLink?.authOption == "NOAUTH" ? "2DS" : "3DS",
          amount: !paymentLink?.amount
            ? "ANY AMOUNT"
            : `${paymentLink?.currency} ${numberWithCommas(
                paymentLink?.amount
              )}`,
          isActive: (
            <>
              <StatusView
                status={paymentLink?.isActive}
                green={true}
                red={false}
              />
            </>
          ),
          action: (
            <PaymentLinkMenu
              reference={paymentLink?.reference}
              status={paymentLink?.isActive}
              dataInfo={paymentLink}
              fetchPaymentLink={fetchPaymentLink}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [paymentLinks?.items]);
  return (
    <div className={styles.paymentLinkWrapper}>
      <div style={{ marginBottom: "16px" }}>
        <ButtonTab
          // activeTab={catalogStatusFilter ?? LINK_STATUS.ACTIVE}
          activeTab={status}
          tabs={PAYMENT_LINK_STATUS_OPTIONS.map((t) => ({
            id: t.id,
            title: t.title,
            value: t.value,
          }))}
          handleTabChange={(value) => {
            setStatus(value);
            setBearer(true);
          }}
        />
      </div>
      <TableHeader
        pageName="Payment links"
        data={paymentLinks?.items}
        value={value}
        setValue={setValue}
        dataLength={paymentLinks?.totalItems}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Create new link
          </div>
        }
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={computeCustomStyles()}
      >
        <CreatePaymentLink
          closeModal={closeModal}
          fetchPaymentLink={fetchPaymentLink}
        />
      </Modal>
      {getPaymentLinksApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnPaymentLinks ? ColumnPaymentLinks : []}
          emptyPlaceHolder="You currently do not have any payment links"
          total={paymentLinks?.totalItems}
        />
      )}
    </div>
  );
};

export default PaymentLinks;
